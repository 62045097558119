import * as Analytics from "expo-firebase-analytics";
import { getCountry, getLocales } from "react-native-localize";
import * as firebase from "firebase/app";
import "firebase/messaging";
import { Environment } from "@app/config";

export function setUserData() {
  /** Topics */
  //messaging().subscribeToTopic("all");

  /** User Properties */
  //Analytics.setUserProperty("country", getCountry());
  Analytics.setUserProperty("language", getLocales()[0].languageCode);
}

export function setupFirebase() {
  return new Promise((resolve, reject) => {
    if (firebase.apps.length) {
      return resolve();
    }

    // @ts-ignore
    firebase.initializeApp(Environment.services.firebase);

    Analytics.setAnalyticsCollectionEnabled(true);

    // Safari shit :(
    if (firebase.messaging.isSupported()) {
      firebase
        .messaging()
        .usePublicVapidKey(Environment.services.firebase.vapid);
    }

    setUserData();
    resolve();
  });
}

export function getPushToken() {
  return new Promise((resolve, reject) => {
    // Safari shit :(
    if (!firebase.messaging.isSupported()) {
      console.warn("Firebase messaging not supported");
      return reject(false);
    }

    firebase
      .messaging()
      .getToken()
      .then((currentToken) => {
        //console.warn("Firebase worker");

        if (currentToken) {
          if (__DEV__) {
            console.log("Current token is " + currentToken);
          }
          resolve(currentToken);
          //sendTokenToServer(currentToken);
          //updateUIForPushEnabled(currentToken);
        } else {
          // Show permission request.
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // Show permission UI.
          //updateUIForPushPermissionRequired();
          //setTokenSentToServer(false);
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        //showToken('Error retrieving registration token. ', err);
        //setTokenSentToServer(false);
      });
  });
}
