/* eslint-disable global-require */
/* eslint-disable no-undef */
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./rootReducer";
import initialState from "@app/config/redux/initialState";
import { persistStore, persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-community/async-storage";

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: "root",
  // Storage Method (React Native)
  storage: AsyncStorage,
  // // Whitelist (Save Specific Reducers)
  // whitelist: ['auth'],
  // // Blacklist (Don't Save Specific Reducers)
  // blacklist: [''],
};

const logger = createLogger({
  stateTransformer: (state: initialState) => {
    if (state.auth!.access_token) {
      state.auth.access_token = "---";
    }

    delete state._persist;

    return state;
  },
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = [thunk];
//const Reactotron = require("@app/config/reactotron/reactroton").default;

if (__DEV__) {
  //const reduxImmutableStateInvariant = require("redux-immutable-state-invariant").default();

  middleware = [...middleware, logger];
} else {
  middleware = [...middleware];
}

function configureStore() {
  return createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middleware)) //, Reactotron.createEnhancer())
  );
}

const store = configureStore();

export default store;
