import * as React from "react";
import {
  Text,
  ScrollView,
  View,
  Platform,
  Image,
  Animated,
} from "react-native";
import { Spinner, Theme, TurboSymbol } from "@app/ui";
import { useRef, useState } from "react";

export default ({ isReady = false }) => {
  //const animatableRef = useRef<Animated.View & View>(null);
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const [displayAs, setDisplayAs] = useState("flex");

  if (isReady) {
    setTimeout(() => {
      Animated.timing(fadeAnim, {
        useNativeDriver: true,
        toValue: 0,
        duration: 150,
      }).start(({ finished }) => {
        if (finished) {
          setDisplayAs("none");
        }
      });
    }, 1000);
  }

  const renderWeb = () => {
    return <Spinner size="large" color={"#fff"}></Spinner>;
  };

  const renderNative = () => {
    return <Spinner color={"#fff"}></Spinner>;
  };

  // if (animatableRef) {
  //   if (isReady) {
  //     animatableRef.transitionTo({ opacity: 1 }, 300, "ease-in-out-quad");
  //   } else {
  //     animatableRef.transitionTo({ opacity: 0 }, 300, "ease-in-out-quad");
  //   }
  // }

  return (
    <Animated.View
      //ref={animatableRef}
      // onTransitionEnd={() => {
      //   try {
      //     //Navigation.dismissOverlay('SPLASH')
      //   } catch (e) {}
      // }}
      style={{
        flex: 1,
        zIndex: 9999,
        backgroundColor: Theme.colors.chrome,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: displayAs,
        opacity: fadeAnim,
      }}
    >
      {Platform.OS === "web" ? renderWeb() : renderNative()}
    </Animated.View>
  );
};
