import * as React from "react";
import {
  View,
  Platform,
  Dimensions,
  StatusBar,
  SafeAreaView,
} from "react-native";
import { Provider, useSelector } from "react-redux";
import AppNavigator from "@app/config/navigation/stack";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { enableScreens, ScreenStack } from "react-native-screens";
import { AppearanceProvider } from "react-native-appearance";
import store from "@app/config/redux/store";
import { Environment } from "@app/config";
import { useEffect, useState } from "react";

import { setupFirebase } from "@app/config/firebase/firebase";
import { Sidebar, SidebarActivity, Splash } from "@app/layout";
import { Theme } from "@app/ui";
import { useFonts } from "expo-font";
import {
  useIsFullScreen,
  useIsLargeScreen,
  useLeftBarWidth,
  useLeftMenu,
} from "@app/utils/hooks/screen_dimensions";

if (Platform.OS === "android") {
  StatusBar.setBackgroundColor("transparent");
  StatusBar.setTranslucent(true);
}

if (Environment.features.music.enabled) {
  const setupPlayer = require("@app/music/services/player").setupPlayer;

  setupPlayer();
}

let PlayerBar: JSX.IntrinsicAttributes | null = null;
if (Platform.OS === "web" && Environment.features.music.enabled) {
  PlayerBar = require("@app/music/components/player_bar").default;
}
//
// import * as Sentry from "sentry-expo";
//
// Sentry.init({
//   dsn:
//     "https://184d52959da143f999f84b85e2013222@o490733.ingest.sentry.io/5555187",
//   enableInExpoDevelopment: true,
//   debug: true, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
// });
//
// import * as SentryReact from "@sentry/react-native";
//
// SentryReact.init({
//   dsn:
//     "https://184d52959da143f999f84b85e2013222@o490733.ingest.sentry.io/5555187",
// });

enableScreens(true);

enum LayoutViewMode {
  LayoutViewModeCompact,
  LayoutViewModeExtended,
  //LayoutViewModeUltra,
}

export default function App() {
  const [symbolsLoaded] = useFonts({
    TurboSymbols: require("./assets/fonts/TurboSymbols.ttf"),
  });

  const [firebaseLoaded, setFirebaseLoaded] = useState(false);

  const [isReady, setIsReady] = useState<boolean>(false);

  const isLoggedIn = false; //useSelector((state) => state.auth.logged_in);
  const isLargeScreen = useIsLargeScreen();
  const isFullScreen = useIsFullScreen();
  const useExtendedLayout = useLeftMenu(); // Platform.OS === "web" || ;
  const leftMenu = useLeftMenu(); // Platform.OS === "web" || ;

  const useMusicPlayer = Environment.features.music.enabled;

  // let layoutMode = useExtendedLayout
  //   ? LayoutViewMode.LayoutViewModeExtended
  //   : LayoutViewMode.LayoutViewModeCompact;
  const sidebarBarWidth = useLeftBarWidth();

  useEffect(() => {
    setupFirebase();
    setTimeout(() => setFirebaseLoaded(true), 100);
  });

  useEffect(() => {
    if (!isReady && symbolsLoaded && firebaseLoaded) {
      setTimeout(() => {
        setIsReady(true);
      }, 300);
    }
  });

  const assetsLoaded = symbolsLoaded && firebaseLoaded;

  if (!assetsLoaded) {
    return <Splash isReady={isReady} />;
  }

  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <AppearanceProvider>
          <Splash isReady={isReady} />
          <View style={{ flex: 1, backgroundColor: "black" }}>
            <View
              style={{
                flex: 1,
                borderWidth: 0,
                borderColor: "red",
                //marginTop: 64,
                backgroundColor: "black",
                // borderTopEndRadius: 8,
                // borderTopLeftRadius: 8,
                overflow: "hidden",
                flexDirection: "row",
              }}
            >
              {leftMenu && <Sidebar />}
              <AppNavigator leftMenu={leftMenu} musicPlayer={useMusicPlayer} />
              {isFullScreen && isLoggedIn && (
                <SafeAreaView
                  style={{
                    backgroundColor: Theme.colors.background,
                    borderLeftColor: Theme.colors.chrome,
                    borderLeftWidth: 0.5,
                    width: sidebarBarWidth,
                    maxWidth: 480,
                  }}
                >
                  <SidebarActivity />
                </SafeAreaView>
              )}
            </View>
          </View>
          {Platform.OS === "web" && useMusicPlayer && (
            <View
              style={{
                height: 88,
                backgroundColor: Theme.colors.chrome,
                borderTopColor: Theme.colors.separator,
                borderTopWidth: 1,
              }}
            >
              <PlayerBar />
            </View>
          )}
        </AppearanceProvider>
      </Provider>
    </SafeAreaProvider>
  );
}
