"use strict";
import React, { Component, ReactNode, useState } from "react";
import {
  Platform,
  Text,
  View,
  ActivityIndicator,
  UIManager,
  LayoutAnimation,
  TouchableHighlight,
  Pressable,
} from "react-native";
import { BlurView } from "@react-native-community/blur";
import Modal, { ModalProps } from "react-native-modal";
import { RectButton } from "react-native-gesture-handler";
import { StyleSheet, Spinner, Theme, Actionable } from "@app/ui";
import tinycolor from "tinycolor2";
//import { gestureHandlerRootHOC } from 'react-native-gesture-handler';

if (
  Platform.OS === "android" &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

interface Props {
  children?: ReactNode;
  onPress: Function;
  text?: string;
  isLoading: boolean;
  backgroundColor?: string;
  style?: any;
}

interface State {
  isVisible: boolean;
  isLoading: boolean;
  backgroundColor: string;
}

const Button = ({
  children,
  onPress,
  text,
  isLoading,
  backgroundColor = Theme.colors.primary,
  style,
}: Props) => {
  // static getDerivedStateFromProps(props: Props, state: State) {
  //   if (props.isLoading !== state.isLoading) {
  //     LayoutAnimation.configureNext(
  //       LayoutAnimation.create(
  //         200,
  //         LayoutAnimation.Types.easeInEaseOut,
  //         LayoutAnimation.Properties.opacity
  //       )
  //     );
  //     return {
  //       isLoading: props.isLoading,
  //     };
  //   }
  //
  //   return null;
  // }

  const renderLoading = () => {
    return <Spinner opacity={0.3} />;
  };

  const renderChildren = () => {
    if (isLoading) {
      return renderLoading();
    }

    let finalBackgroundColor = isLoading ? "#cccccc" : backgroundColor;

    const color = tinycolor(finalBackgroundColor).isDark()
      ? "#ffffff"
      : "#000000";

    return (
      <View>
        {text && (
          <Text
            style={{
              color: color,
              height: 44,
              lineHeight: 44,
              textAlign: "center",
              fontSize: 15,
              fontFamily: Theme.font.medium,
            }}
          >
            {text}
          </Text>
        )}
        {children}
      </View>
    );
  };

  let superBackgroundColor = isLoading ? "#cccccc50" : backgroundColor;

  // return (
  //   <Actionable
  //     onPress={props.onPress.bind(this)}
  //     style={{
  //       backgroundColor: backgroundColor, // superBackgroundColor, //this.state.appearance.colors.tertiary,
  //       height: 44,
  //       borderRadius: 4,
  //     }}
  //     highlightColor={superBackgroundColor}
  //   >
  //     {renderChildren()}
  //   </Actionable>
  // );

  if (Platform.OS === "android") {
    return (
      <TouchableHighlight
        onPress={onPress.bind(this)}
        style={{
          backgroundColor: superBackgroundColor, //this.state.appearance.colors.tertiary,
          height: 44,
          borderRadius: 4,
          ...style,
        }}
        underlayColor="#fff"
      >
        {renderChildren()}
      </TouchableHighlight>
    );
  } else {
    return (
      <Pressable
        style={{
          backgroundColor: superBackgroundColor, //this.state.appearance.colors.tertiary,
          height: 44,
          borderRadius: 4,
          ...Platform.select({
            web: {
              cursor: "pointer",
            },
          }),
          ...style,
        }}
        onPress={onPress.bind(this)}
      >
        {renderChildren()}
      </Pressable>
    );
  }
};

export default Button;
