import * as React from "react";

import {
  Platform,
  View,
  Text,
  TouchableOpacity,
  Pressable,
} from "react-native";
import { Theme } from "@app/ui";
import { each } from "lodash";

import { HomeScreen, NotificationsListScreen } from "@app/activity";
import { NewsStoriesDetailScreen, NewsStoriesListScreen } from "@app/news";
import {
  GamesListScreen,
  GameDetailScreen,
  GamePlatformsListScreen,
  GamesCollectionsListScreen,
  GamesCollectionsShow,
} from "@app/games";
import { UserProfileScreen } from "@app/user";
import { MusicDemoScreen, MusicQueueScreen } from "@app/music";
import { UploadScreen } from "@app/publisher";
import { ChatConversationScreen, ChatConversationsListScreen } from "@app/chat";
import createStack from "./createStack";

import { HeaderWeb, isElectron, isWeb } from "@app/config";
import WebViewScreen from "@app/utils/components/webview";
import CameraCodeScannerScreen from "@app/games/screens/camera";

const allScreens = [
  { name: "Home", component: HomeScreen },
  { name: "NotificationsList", component: NotificationsListScreen },

  //News
  { name: "NewsList", component: NewsStoriesListScreen },
  {
    name: "NewsStory",
    component: NewsStoriesDetailScreen,
    options: { title: "" },
  },
  //Games
  { name: "GamePlatformList", component: GamePlatformsListScreen },
  { name: "GamesList", component: GamesListScreen },
  { name: "GameDetail", component: GameDetailScreen, options: { title: "" } },
  {
    name: "GamesCollectionsList",
    component: GamesCollectionsListScreen,
    options: { title: "Collections" },
  },
  {
    name: "GamesCollectionsShow",
    component: GamesCollectionsShow,
    options: { title: "Collection" },
  },
  //Music
  { name: "MusicDemo", component: MusicDemoScreen },
  { name: "MusicQueue", component: MusicQueueScreen },
  //Publish
  {
    name: "PublishUpload",
    component: UploadScreen,
    options: { title: "Manager" },
  },
  //Chat
  {
    name: "ChatConversationsList",
    component: ChatConversationsListScreen,
    options: { title: "Chats" },
  },
  {
    name: "ChatConversation",
    component: ChatConversationScreen,
    options: { title: "Conversation" },
  },

  // Users
  { name: "UserProfile", component: UserProfileScreen, options: { title: "" } },

  // Utils
  { name: "WebView", component: WebViewScreen, options: { title: "" } },
  {
    name: "CameraCodeScanner",
    component: CameraCodeScannerScreen,
    options: { title: "", headerShown: false },
  },
];

if (isWeb) {
  const iconFonts = {
    turbosymbols: require("@assets/fonts/TurboSymbols.ttf"),
    FontAwesome5_Pro_Solid: require("@assets/fonts/FontAwesome5_Pro_Solid.ttf"),
    FontAwesome5_Pro_Light: require("@assets/fonts/FontAwesome5_Pro_Light.ttf"),
    "Rubik-Light": require("@assets/fonts/Rubik-Light.ttf"),
    "Rubik-Regular": require("@assets/fonts/Rubik-Regular.ttf"),
    "Rubik-Medium": require("@assets/fonts/Rubik-Medium.ttf"),
    // "EuclidSquare-Light": require("@assets/fonts/EuclidSquare-Light.ttf"),
    // "EuclidSquare-Regular": require("@assets/fonts/EuclidSquare-Regular.ttf"),
    // "EuclidSquare-Medium": require("@assets/fonts/EuclidSquare-Medium.ttf"),
  };

  each(iconFonts, (value, key) => {
    const fontSrc = isElectron ? value.default : value;
    // Generate required css
    const iconFontStyles = `@font-face {
  src: url(${fontSrc});
  font-family: ${key};
}`;

    // Create stylesheet
    const style = document.createElement("style");
    style.type = "text/css";
    if (style.styleSheet) {
      style.styleSheet.cssText = iconFontStyles;
    } else {
      style.appendChild(document.createTextNode(iconFontStyles));
    }

    // Inject stylesheet
    document.head.appendChild(style);
  });
}

const defaultScreenOptions = {
  headerStyle: { backgroundColor: Theme.colors.background },
  headerTitleStyle: {
    fontFamily: Theme.font.medium,
  },
  headerBackTitleStyle: {
    fontFamily: Theme.font.regular,
  },
  headerLargeTitle: false,
  headerHideShadow: true,
  headerTranslucent: false,
  ...Platform.select({
    web: {
      headerMode: "screen",
      header: HeaderWeb,
      headerTitleAlign: "center",
      headerStyle: {
        WebkitUserSelect: "none",
        WebkitAppRegion: "drag",
      },
      headerTitleStyle: {
        WebkitUserSelect: "none",
        WebkitAppRegion: "drag",
      },
    },
  }),
  //animationEnabled: true,
};

const createStackWithScreens = (
  name: string,
  initialRouteName: string,
  mode: null,
  screens: Array<any>
) => {
  const MyStack = createStack();

  return (
    <MyStack.Navigator
      mode={mode}
      initialRouteName={initialRouteName}
      screenOptions={defaultScreenOptions}
      options={{ headerLargeTitle: true }}
    >
      {screens.map((screen) => {
        return (
          <MyStack.Screen
            key={screen.name}
            name={screen.name}
            component={screen.component}
            options={screen.options}
          />
        );
      })}
    </MyStack.Navigator>
  );
};

export const createStackWithAllScreens = (
  name: string,
  initialRouteName: string,
  mode?: null
) => createStackWithScreens(name, initialRouteName, mode, allScreens);

/** Home Stack */
export function HomeStackScreen({ navigation }) {
  return createStackWithAllScreens("Home", "Home");
}

/** Activity Stack */
export function ActivityStackScreen({ navigation }) {
  return createStackWithAllScreens("Activity", "ChatConversationsList");
}

/** News Stack */
export function NewsStackScreen({ navigation }) {
  return createStackWithAllScreens("News", "NewsList");
}

/** Games Stack */
export function GamesStackScreen({ navigation }) {
  return createStackWithAllScreens("Games", "GamePlatformList");
}

/** Collection Stack */
export function CollectionsStackScreen({ navigation }) {
  return createStackWithAllScreens("Collections", "GamesCollectionsList");
}

/** Music Music */
export function MusicStackScreen({ navigation }) {
  return createStackWithAllScreens("Music", "MusicDemo");
}

/** Manage */
export function ManageStackScreen({ navigation }) {
  return createStackWithAllScreens("Manage", "PublishUpload");
}

/** Users Stack */
export function UsersStackScreen({ navigation }) {
  return createStackWithAllScreens("Users", "UserProfile");
}

/** Chat Stack */
export function ChatStackScreen({ navigation }) {
  return createStackWithAllScreens("Chat", "ChatConversation");
}
