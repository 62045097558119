"use strict";
import React, { Component, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  Platform,
} from "react-native";
import {
  TouchableOpacity,
  BorderlessButton,
  RectButton,
  BaseButton,
  ScrollView,
} from "react-native-gesture-handler";
import { Modal, Card, Button, StyleSheet, Theme } from "@app/ui";
import FBSDK, { AccessToken, LoginManager } from "react-native-fbsdk";
import { setCredentials, postSocialLogin } from "@app/auth/services/data";
import Auth from "@app/auth/services/auth.service";
import { connect, useDispatch, useSelector } from "react-redux";
import * as authActions from "@app/user/actions/auth.action";
import * as AppleAuthentication from "expo-apple-authentication";
import * as WebBrowser from "expo-web-browser";
import {
  makeRedirectUri,
  ResponseType,
  useAuthRequest,
} from "expo-auth-session";
import { Environment } from "@app/config";

enum LoginAuthProvider {
  Internal = "app",
  Facebook = "facebook",
  Apple = "apple",
}

interface Props {
  isVisible: boolean;
  onClose?: Function;
}

const LoginAuthProvidersDiscovery = {
  facebook: {
    authorizationEndpoint: "https://www.facebook.com/v6.0/dialog/oauth",
    tokenEndpoint: "https://graph.facebook.com/v6.0/oauth/access_token",
  },
};

WebBrowser.maybeCompleteAuthSession();
const useProxy = Platform.select({ web: false, default: true });

const LoginModal = ({ isVisible, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.logged_in);
  const user = useSelector((state) => state.auth.user);

  const discovery = LoginAuthProvidersDiscovery[LoginAuthProvider.Facebook];
  const facebookService = Environment.services.facebook;

  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: facebookService.appId,
      scopes: facebookService.scopes,
      responseType: ResponseType.Token,
      // For usage in managed apps using the proxy
      redirectUri: makeRedirectUri({
        useProxy: false,
        // For usage in bare and standalone
        // Use your FBID here. The path MUST be `authorize`.
        native: "fb" + facebookService?.appId + "://authorize",
      }),
      extraParams: {
        // Use `popup` on web for a better experience
        display: Platform.select({ web: "popup" }),
        // Optionally you can use this to rerequest declined permissions
        auth_type: "rerequest",
      },
    },
    discovery
  );

  React.useEffect(() => {
    if (response?.type === "success") {
      setIsLoading(true);
      const { access_token } = response.params;
      console.warn("FACEBOOK OK", response.params);
      postSocialLogin("facebook", access_token).then(handleLoginResponse);
    }
  }, [response]);

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  const loginWithApple = async () => {
    setIsLoading(true);
    let accessToken = undefined;

    try {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });

      accessToken = credential.identityToken;

      // signed in
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        // handle that the user canceled the sign-in flow
      } else {
        // handle other errors
        console.error(e);
      }
      setIsLoading(false);
    }

    if (accessToken) {
      postSocialLogin("apple", accessToken).then(handleLoginResponse);
    }
  };

  const loginWithFacebookWeb = () => {
    promptAsync({ useProxy, windowFeatures: { width: 700, height: 600 } });
  };

  const loginWithFacebook = () => {
    setIsLoading(true);
    // Attempt a login using the Facebook login dialog asking for default permissions.
    LoginManager.logInWithPermissions(facebookService?.scopes).then(
      (result) => {
        if (result.isCancelled) {
          //alert('Login cancelled');
          setIsLoading(false);
        } else {
          // alert(
          //   `Login success with permissions: ${result.grantedPermissions.toString()}`,
          // );

          AccessToken.getCurrentAccessToken().then((data) => {
            //alert(data.accessToken.toString());
            postSocialLogin("facebook", data.accessToken.toString()).then(
              handleLoginResponse
            );
          });
        }
      },
      (error) => {
        alert(`Login fail with error: ${error}`);
        setIsLoading(false);
      }
    );
  };

  const handleLoginResponse = (response) => {
    if (response.data.access_token) {
      Auth.getInstance()
        .saveCredentials(response.data.access_token)
        .then(() => {
          dispatch(authActions.setLoggedIn(response.data.access_token));
          close();
        });
    } else {
      setIsLoading(false);
      Alert.alert("Ups, something happened");
      console.error(response);
    }
  };

  return (
    <Modal isVisible={isVisible} onClose={close}>
      <Card>
        <Text
          style={{
            paddingVertical: 24,
            textAlign: "center",
            color: Theme.colors.text, //StyleSheet.value("$textColor"),
            fontFamily: Theme.font.medium,
            fontSize: 16,
          }}
        >
          Continue with Facebook
        </Text>
        <Button
          onPress={() => {
            Platform.OS === "web"
              ? loginWithFacebookWeb()
              : loginWithFacebook();
          }}
          isLoading={isLoading}
          text="Login with Facebook"
        />

        {Platform.OS === "ios" && (
          <AppleAuthentication.AppleAuthenticationButton
            buttonType={
              AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN
            }
            buttonStyle={
              AppleAuthentication.AppleAuthenticationButtonStyle.BLACK
            }
            cornerRadius={4}
            style={{ height: 44, marginTop: 8 }}
            onPress={loginWithApple}
          />
        )}
      </Card>
    </Modal>
  );
};

const stylesText = StyleSheet.create({});

// function mapStateToProps(state, ownProps) {
//   return {
//     loggedIn: state.auth.logged_in,
//     user: state.auth.user,
//     loading: state.auth.loading,
//   };
// }
// function mapDispatchToProps(dispatch) {
//   return {
//     auth: bindActionCreators(authActions, dispatch),
//   };
// }

export default LoginModal;
