import * as React from "react";
import {
  ScrollView,
  View,
  RefreshControl,
  FlatList,
  Pressable,
} from "react-native";

import { Audio, Video } from "expo-av";
import { useEffect, useRef, useState } from "react";
import { getGames } from "@app/games/services/data";
import { concat } from "lodash";

import { instance as api } from "@app/config/api/api";
import { ImageBox, Text } from "@app/ui";
import { Track } from "@app/music/models/track";
import { addTrack } from "@app/music/services/player";
import {
  useContentWidth,
  useIsLargeScreen,
  useNumberOfColumnsForFullScreen,
} from "@app/utils/hooks/screen_dimensions";

export default () => {
  const videoRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const deviceWidth = useContentWidth();
  const itemSize = useIsLargeScreen() ? 220 : 180;
  const coverSize = itemSize - 16;
  const rowSize = Math.floor(deviceWidth / itemSize);
  const numberOfColumns = useNumberOfColumnsForFullScreen(itemSize);

  useEffect(() => {
    if (!loading) getMyItems(currentPage);
  }, [currentPage]);

  const getMyItems = (page: number) => {
    if (page > 1) {
      setLoading(true);
    }

    const params = { page };

    api.request({ url: "podcasts", params }).then((response) => {
      const { data } = response;

      const mergedItems = page === 1 ? data.data : concat(items, data.data);

      setLoadMore(data.links.next ? true : false);
      setItems(mergedItems);
      setCurrentPage(page);
      setLoading(false);
    });
  };

  const addSongToQueue = (track: Track) => {
    const generatedID = track.id + "_" + Date.now();
    const finalTrack = { ...track, id: generatedID };

    return addTrack(finalTrack);
  };

  const renderItemCell = ({ item }) => {
    const cover = item.media[0];
    const stream = item.av_media[0]?.streams[0];
    const name = item.name || "Podcast";
    const track = stream
      ? {
          id: stream.uuid,
          url: stream.content.url,
          artist: name,
          album: name,
          title: name,
          artwork: cover.url,
        }
      : null;

    return (
      <Pressable
        style={{
          //display: "flex",
          //flex: 1 / numberOfColumns,
          //flexGrow: 1,
          flexBasis: 100 / numberOfColumns + "%",
          padding: 8,
        }}
        onPress={() => {
          track ? addSongToQueue(track) : alert("Not available");
        }}
      >
        <ImageBox
          source={{ uri: cover.url }}
          backgroundColor={cover?.colors?.primary}
          borderRadius={4}
        />
        <View style={{ marginVertical: 8 }}>
          <Text style={{ opacity: 0.7 }}>Artist</Text>
          <Text style={{ fontWeight: "600" }}>{item.name}</Text>
        </View>
      </Pressable>
    );
  };

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        data={items}
        numColumns={numberOfColumns}
        refreshControl={
          <RefreshControl
            refreshing={loading}
            onRefresh={() => getMyItems(1)}
          />
        }
        columnWrapperStyle={{
          flex: 1,
          justifyContent: "flex-start",
        }}
        key={"podcasts-" + numberOfColumns}
        renderItem={renderItemCell}
        keyExtractor={(item, index) => index.toString()}
        ItemSeparatorComponent={() => (
          <View
            style={{
              //height: 4,
              flex: 1,
              backgroundColor: "rgba(255,255,255,.1)",
              //marginTop: -18,
            }}
          />
        )}
        //ListFooterComponent={renderFooter}
        onEndReachedThreshold={0.4}
        style={{
          padding: 16,
        }}
        //onEndReached={handleLoadMore}
      />

      {/*<Video*/}
      {/*  ref={videoRef}*/}
      {/*  source={{*/}
      {/*    uri:*/}
      {/*      "https://stream.mux.com/CTbxXD8TWH01JhfmuLJqXgfeg1ENEz02uwxGax02BmQdSo.m3u8",*/}
      {/*  }}*/}
      {/*  useNativeControls={true}*/}
      {/*  style={{*/}
      {/*    flex: 1,*/}
      {/*    height: 320,*/}
      {/*    borderWidth: 1,*/}
      {/*    borderColor: "red",*/}
      {/*  }}*/}
      {/*/>*/}
    </View>
  );
};
