"use strict";
import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import {
  Animated,
  ActivityIndicator,
  AppRegistry,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  FlatList,
  RefreshControl,
  Dimensions,
  Platform,
  SafeAreaView,
  TouchableWithoutFeedback,
  Alert,
  Image as ReactImage,
} from "react-native";
import {
  StyleSheet,
  Modal,
  Card,
  Button,
  Spinner,
  BoxResponsive,
  Theme,
  ImageBox,
} from "@app/ui";
import { LinearGradient } from "expo-linear-gradient";
import axios from "axios";
import { Image } from "@app/ui";
import { getGame } from "@app/games/services/data";
import { find, map, orderBy, flatten, findKey, pickBy } from "lodash";
import tinycolor from "tinycolor2";
import { BlurView } from "expo-blur";
import { connect, useSelector } from "react-redux";
import StaticSafeAreaInsets from "react-native-static-safe-area-insets";
import { useNavigation } from "@react-navigation/native";
import { useHeaderHeight } from "@react-navigation/stack";
import GameInCollectionModal from "@app/games/components/collection_modal";
import { isWeb } from "@app/config";
import { useContentWidth } from "@app/utils/hooks/screen_dimensions";
if (Platform.OS !== "web") {
  const GallerySwiper = require("react-native-gallery-swiper");
}

let ScreenDefault = {
  height: Dimensions.get("window").height,
  statusBarHeight: 0,
  navBarHeight: 44,
  totalTopHeight: 0,
  heroHeight: 0,
  ready: true,
};

interface Screen {
  height: number;
  statusBarHeight: number;
  navBarHeight: number;
  totalTopHeight: number;
  heroHeight: number;
  ready: boolean;
}

interface GameModel {
  name: string;
  media: Array<MediaModel>;
}

enum GameTheme {
  dark = "dark",
  light = "light",
}

interface GameAppearance {
  theme: GameTheme;
  colors: MediaColors;
}

interface MediaModel {
  url: string;
  thumb_url: string;
  colors: MediaColors;
  aspect_ratio?: Number;
}

interface MediaColors {
  primary: string;
  secondary: string;
  tertiary: string;
}

interface Props {
  navigation: any;
  id: number;
  game?: object;
}

interface State {
  loading: boolean;
  game?: GameModel;
  appearance: GameAppearance;
  modalAddCollectionVisible: boolean;
  modalGalleryVisible: boolean;
  modalGalleryImage: number;
  screen: Screen;
}

const GameDetailScreen = ({ route }: Props) => {
  const { gameOriginal, id } = route.params || {};

  const _deltaY = new Animated.Value(0);
  const [media, setMedia] = useState<Array<any>>([]);
  const [game, setGame] = useState(gameOriginal);
  const [modalAddCollectionVisible, setModalAddCollectionVisible] = useState(
    false
  );
  const [modalGalleryVisible, setModalGalleryVisible] = useState(false);
  const navigation = useNavigation();
  //const headerHeight = useHeaderHeight();
  const isLoggedIn = useSelector((state) => state.auth.logged_in);

  // const [title, setTitle] = useState(
  //   props.route.params?.title || props.route.params?.game?.name || "Game"
  // );

  const [loading, setLoading] = useState(false);
  const [appearance, setAppearance] = useState<GameAppearance>({
    theme: GameTheme.light,
    colors: {
      primary: Theme.colors.chrome,
      secondary: Theme.colors.text,
      tertiary: Theme.colors.primary,
    },
  });
  const [screen, setScreen] = useState<Screen>(ScreenDefault);
  const contentWidth = useContentWidth();

  // useLayoutEffect(() => {
  //   // navigation.setOptions({
  //   //   title: title,
  //   // });
  // });

  const getGameDetail = (gameId) => {
    if (game) {
      mapGame(game);
    } else {
      setLoading(true);
    }

    getGame(gameId).then((response) => {
      const { data } = response;
      let game = data.data;
      mapGame(game);
    });
  };

  const mapGame = (gameData) => {
    const cover = find(gameData.media, { type: "cover" });
    const hero = find(gameData.media, { type: "hero" });
    let newAppearance = appearance;

    if (cover && "colors" in cover && cover.colors !== null) {
      //appearance.colors = cover.colors;
    }

    if (hero && "colors" in hero && hero.colors !== null) {
      newAppearance.colors = hero.colors;
    }

    const heroHeight =
      hero && "aspect_ratio" in hero ? contentWidth / hero.aspect_ratio : 100;

    newAppearance.theme = tinycolor(newAppearance.colors.primary).isDark()
      ? GameTheme.dark
      : GameTheme.light;

    navigation.setOptions({
      title: "",
      headerTranslucent: false,
      headerTransparent: true,
      headerStyle: {
        backgroundColor: "transparent", // newAppearance.colors.primary,
      },
      headerBackTitleStyle: {
        color: newAppearance.colors.primary,
        fontFamily: Theme.font.regular,
      },
    });

    // Navigation.mergeOptions(this.props.componentId, {
    //   statusBar: {
    //     ...Platform.select({
    //       ios: {
    //         style: appearance.theme === GameTheme.dark ? "light" : "dark",
    //       },
    //     }),
    //   },
    //   topBar: {
    //     backButton: {
    //       color: appearance.colors.tertiary,
    //       visible: false,
    //     },
    //   },
    // });

    setLoading(false);

    // this.setState({
    //   loading: false,
    //   game: game,
    //   appearance: appearance,
    // });

    // this.setState({
    //   screen: {
    //     ...this.state.screen,
    //     heroHeight: heroHeight,
    //   },
    // });

    setScreen({ ...screen, heroHeight });

    let releasesMedia = map(gameData.releases, (release) => {
      return map(release.media, (media) => {
        return {
          source: { url: media.url },
          dimensions: {
            width: ScreenDefault.height * media.aspect_ratio,
            height: ScreenDefault.height,
          },
        };
      });
    });
    //releasesMedia = map(releasesMedia, "media");
    setMedia(map(flatten(releasesMedia)));
    setAppearance(newAppearance);
    setGame(gameData);
    //console.warn(this.media);
  };

  // renderSeparator = () => {
  //   return (
  //     <View
  //       style={{
  //         height: 2,
  //         width: "100%",
  //         backgroundColor: "#CED0CE"
  //       }}
  //     />
  //   );
  // };

  // renderFooter = () => {
  //   //it will show indicator at the bottom of the list when data is loading otherwise it returns null
  //   if (!this.state.loading) return null;
  //   return <ActivityIndicator />;
  // };

  const renderHeroImage = () => {
    const hero = find(game.media, { type: "hero" });
    const cover = find(game.media, { type: "cover" });

    return (
      <View>
        {hero && (
          <Animated.View
            style={{
              transform: [
                {
                  scale: _deltaY.interpolate({
                    inputRange: [
                      -300,
                      -300,
                      0, //-StaticSafeAreaInsets.safeAreaInsetsTop,
                      8, //-StaticSafeAreaInsets.safeAreaInsetsTop,
                    ],
                    outputRange: [3, 3, 1, 1],
                  }),
                },
              ],
              useNativeDriver: true,
            }}
          >
            <ImageBox
              source={{
                uri: hero.url,
              }}
              sourceThumb={{
                uri: hero.thumb_url,
              }}
              backgroundColor={appearance.colors.primary}
              //width={Math.min(600, Dimensions.get("window").width)}
              aspectRatio={hero.aspect_ratio}
              //height={hero.aspect_ratio}
              style={{
                //width: "100%",
                // marginTop: 44,
                // borderTopLeftRadius: 12,
                // borderTopRightRadius: 12,
                //height: screen.heroHeight,
                //backgroundColor: appearance.colors.primary,
                ...Platform.select({
                  web: {
                    borderRadius: 20,
                    marginTop: 72,
                  },
                }),
              }}
              resizeMode={"contain"}
            />
          </Animated.View>
        )}
        {!hero && cover && (
          <ImageBox
            key={"game" + game.id}
            width={Math.min(240, contentWidth / 2.5)}
            aspectRatio={cover.aspect_ratio}
            containerStyle={{ zIndex: 3, alignSelf: "center", marginTop: 16 }}
            backgroundColor={cover.colors.primary}
            source={{
              uri: cover.url,
            }}
            sourceThumb={{
              uri: cover.thumb_url,
            }}
          />
        )}
        <LinearGradient
          colors={[appearance.colors.primary + "00", appearance.colors.primary]}
          style={{
            width: "100%",
            height: screen.heroHeight ? screen.heroHeight / 4 : 0,
            zIndex: 2,
            position: "absolute",
            bottom: 0,
          }}
        />
      </View>
    );
  };

  const renderPlatforms = (item) => {
    return item.platforms.map((platform) => {
      const logo = find(platform.media, { type: "logo" });
      if (!logo) return <View />;

      return (
        <Image
          key={platform.id}
          source={{ uri: logo.url }}
          style={{
            width: 24,
            height: 24,
            marginHorizontal: 8,
            tintColor: appearance.colors.secondary,
          }}
          tintColor={appearance.colors.secondary}
        />
      );
    });
  };

  const renderSummary = (item) => {
    return (
      <View
        style={{
          backgroundColor: appearance.colors.primary,
          padding: 24,
          paddingBottom: 4,
          borderBottomEndRadius: 20,
          borderBottomStartRadius: 20,
        }}
      >
        <Text
          style={{
            textAlign: "center",
            color: appearance.colors.secondary,
            fontSize: 22,
            letterSpacing: -0.8,
            fontFamily: Theme.font.medium,
          }}
        >
          {item.name}
        </Text>

        <Text
          style={{
            textAlign: "center",
            color: appearance.colors.secondary,
            fontSize: 14,
            opacity: 0.7,
            marginTop: 6,
            fontFamily: Theme.font.regular,
          }}
        >
          PEGI - SEGA
        </Text>
        <View style={{ paddingVertical: 16 }}>
          <Button
            text="Add to My Collection"
            isLoading={modalAddCollectionVisible}
            backgroundColor={appearance.colors.tertiary}
            onPress={() => {
              if (isLoggedIn) {
                setModalAddCollectionVisible(!modalAddCollectionVisible);
              } else {
                Alert.alert("Please log in");
              }
            }}
          />
        </View>
        {item.platforms && item.platforms.length > 0 && (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: 16,
            }}
          >
            {renderPlatforms(item)}
          </View>
        )}
        {/* <Text
          style={{
            color: this.state.appearance.colors.secondary,
            fontSize: 14,
            marginTop: 8
          }}
        >
          {item.storyline || item.summary || ""}
        </Text> */}
      </View>
    );
  };

  const renderNavigationBar = () => {
    let limit = Math.max(
      screen.heroHeight || 0,
      160 + 32 + screen.navBarHeight
    );
    if (Platform.OS === "ios") {
      limit -= Math.max(0, screen.statusBarHeight);
    }
    const buttonSize = 28;
    const backgroundColorAlpha = tinycolor(appearance.colors.primary)
      .setAlpha(0)
      .toRgbString();

    return (
      <Animated.View
        style={{
          // opacity: this._deltaY.interpolate({
          //   inputRange: [0, 160],
          //   outputRange: [0, 1]
          // }),
          useNativeDriver: true,
          position: "absolute",
          zIndex: 2,
          paddingVertical:
            Platform.OS === "android" ? 0 : screen.statusBarHeight,
          backgroundColor: _deltaY.interpolate({
            inputRange: [0, Math.max(0, limit - screen.statusBarHeight)],
            outputRange: [backgroundColorAlpha, appearance.colors.primary],
            extrapolate: "clamp",
          }),
          width: "100%",
          height: screen.totalTopHeight,
        }}
      >
        <View
          style={{
            borderWidth: 0,
            borderColor: "blue",
            position: "relative",
            height: screen.navBarHeight,
            paddingHorizontal: 12,
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "stretch",
          }}
        >
          <TouchableOpacity
            onPress={() => Navigation.pop(this.props.componentId)}
            style={{ width: buttonSize, justifyContent: "center" }}
          >
            {/*<ReactImage*/}
            {/*  source={require("@assets/icons/icon_back_rounded_inverted.png")}*/}
            {/*  style={{*/}
            {/*    width: buttonSize,*/}
            {/*    height: buttonSize,*/}
            {/*    backgroundColor: appearance.colors.tertiary,*/}
            {/*    tintColor: tinycolor(appearance.colors.tertiary).isDark()*/}
            {/*      ? "#ffffff"*/}
            {/*      : "#000000",*/}
            {/*    borderRadius: buttonSize / 2,*/}
            {/*  }}*/}
            {/*></ReactImage>*/}
          </TouchableOpacity>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Animated.Text
              style={{
                textAlign: "center",
                color: appearance.colors.secondary,
                fontFamily: Theme.font.medium,
                fontSize: 17,
                letterSpacing: -0.4,
                opacity: _deltaY.interpolate({
                  inputRange: [
                    0,
                    Math.max(0, limit - screen.totalTopHeight),
                    limit,
                    limit + 1,
                  ],
                  outputRange: [0, 0, 1, 1],
                }),
                transform: [
                  {
                    translateY: _deltaY.interpolate({
                      inputRange: [
                        0,
                        Math.max(0, limit - screen.totalTopHeight),
                        limit,
                        limit + 1,
                      ],
                      outputRange: [
                        screen.navBarHeight / 2,
                        screen.navBarHeight / 2,
                        0,
                        0,
                      ],
                    }),
                  },
                ],
              }}
            >
              {game ? game.name : ""}
            </Animated.Text>
          </View>
          <View style={{ width: buttonSize, justifyContent: "center" }}></View>
        </View>
      </Animated.View>
    );
  };

  const renderReleases = (item) => {
    //const grouped = groupBy(item.releases, release => release.platform.id);

    if (!item.releases) {
      return <View></View>;
    }

    const ordered = orderBy(item.releases, ["date", "desc"]);
    //console.warn(ordered);

    return (
      <View
        style={{
          borderTopColor: Theme.colors.separator,
          borderTopWidth: 0.5,
        }}
      >
        <Text
          style={{
            marginTop: 2,
            fontSize: 18,
            alignItems: "center",
            paddingHorizontal: 24,
            paddingTop: 24,
            paddingBottom: 12,
            color: Theme.colors.text,
            fontFamily: Theme.font.medium,
          }}
        >
          Releases
        </Text>
        {ordered.map((release) => {
          const logo = find(release.platform.media, { type: "logo" });
          const covers = release.media || [];
          const releaseDate = [
            release.date.year,
            release.date.month,
            release.date.day,
          ]
            .filter(Boolean)
            .join("-")
            .trim();
          return (
            <View
              key={release.id}
              style={{
                paddingHorizontal: 24,
                paddingVertical: 12,
                // borderBottomColor: '#eee',
                // borderBottomWidth: 0.5,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: 28,
                    marginRight: 8,
                    //marginRight: 6,
                  }}
                >
                  {logo && (
                    <View
                      style={{
                        // backgroundColor: tinycolor(
                        //   this.state.appearance.colors.tertiary,
                        // )
                        //   .setAlpha(0.1)
                        //   .toRgbString(),
                        backgroundColor: appearance.colors.primary,
                        padding: 6,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: -1,
                        borderRadius: 4,
                      }}
                    >
                      <Image
                        key={release.id}
                        source={{ uri: logo.url }}
                        style={{ width: 18, height: 18 }}
                        tintColor={appearance.colors.secondary}
                      />
                    </View>
                  )}
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: -4,
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Text
                      style={{
                        marginRight: 4,
                        opacity: 0.5,
                        color: Theme.colors.text,
                        fontSize: 13,
                        fontFamily: Theme.font.regular,
                      }}
                    >
                      {/* {release.platform.name} */}
                      {releaseDate}
                    </Text>
                    <View style={{ flexDirection: "row", marginTop: -1 }}>
                      {release.worlwide ? (
                        <Text style={styles.country}>Worldwide</Text>
                      ) : (
                        release.countries.map((country) => (
                          <Text style={styles.country}>{country}</Text>
                        ))
                      )}
                    </View>
                  </View>
                  {release.platform && (
                    <Text
                      style={{
                        paddingTop: 2,
                        fontWeight: "500",
                        color: Theme.colors.text,
                        fontFamily: Theme.font.regular,
                      }}
                    >
                      {/* {release.platform.name} */}
                      {release.platform.name}
                    </Text>
                  )}
                  {release.description && (
                    <Text
                      style={{
                        paddingTop: 2,
                        color: Theme.colors.text,
                        fontFamily: Theme.font.regular,
                        opacity: 0.7,
                        //fontWeight: '500',
                      }}
                    >
                      {/* {release.platform.name} */}
                      {release.description}
                    </Text>
                  )}
                  {covers && covers.length > 0 && (
                    <View
                      style={{
                        flexDirection: "row",
                        //justifyContent: "center",
                        marginTop: 6,
                        marginBottom: 4,
                        alignItems: "baseline",
                      }}
                    >
                      {covers.map((cover) => {
                        return (
                          <TouchableOpacity
                            onPress={() => {
                              const imageIndex = parseInt(
                                findKey(
                                  pickBy(media, {
                                    source: { url: cover.url },
                                  })
                                )
                              );
                              //console.warn(imageIndex);
                              this.setState({
                                modalGalleryImage: imageIndex,
                              });
                              setModalGalleryVisible(!modalGalleryVisible);

                              this.openGallery(imageIndex);
                            }}
                            underlayColor="#fff"
                          >
                            <ImageBox
                              source={{
                                uri: cover.url,
                              }}
                              sourceThumb={{
                                uri: cover.thumb_url,
                              }}
                              backgroundColor={cover.colors.primary}
                              aspectRatio={cover.aspect_ratio}
                              width={54 * cover.aspect_ratio}
                              style={{
                                marginHorizontal: 2,
                                //borderWidth: 1,
                                //borderColor: "red"
                              }}
                            />
                          </TouchableOpacity>
                        );
                      })}
                    </View>
                  )}
                </View>
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  const openGallery = (imageIndex) => {
    Navigation.showModal({
      stack: {
        children: [
          {
            component: {
              name: "retrollector.gallery",
              passProps: {
                images: media,
                initialPage: imageIndex,
              },
              options: {
                modalPresentationStyle: "fullScreen",
                statusBar: {
                  style: "light",
                },
                topBar: {
                  visible: false,
                  background: {
                    color: "transparent",
                  },
                },
                modal: {
                  swipeToDismiss: false,
                },
                animations: {
                  showModal: {
                    alpha: {
                      from: 0,
                      to: 1,
                      duration: 250,
                    },
                  },
                },
              },
            },
          },
        ],
      },
    });
  };

  const renderGallery = () => {
    let modalProps = {};
    if (Platform.OS === "ios") {
      modalProps.customBackdrop = (
        <Pressable onPress={() => setModalGalleryVisible(true)}>
          <BlurView
            style={{ flex: 1 }}
            blurType="dark"
            blurAmount={50}
          ></BlurView>
        </Pressable>
      );
      modalProps.backdropOpacity = 1;
    }
    return (
      <Modal
        isVisible={modalGalleryVisible}
        onBackdropPress={() => this.setState({ modalGalleryVisible: false })}
        onSwipeComplete={() => this.setState({ modalGalleryVisible: false })}
        swipeThreshold={120}
        style={{
          margin: 0,
          justifyContent: "flex-end",
        }}
        // hideModalContentWhileAnimating={true}
        // useNativeDriver={true}
        animationIn="fadeIn"
        animationOut="fadeOut"
        {...modalProps}
      >
        {/* <Gallery
          style={{ flex: 1, backgroundColor: "transparent" }}
          initialPage={this.state.modalGalleryImage}
          images={this.media}
          animationType="fade"
          onSingleTapConfirmed={() =>
            this.setState({ modalGalleryVisible: false })
          }
          imageComponent={(image, dimensions) => {
            return (
              <SafeAreaView style={{ flex: 1 }}>
                <Image
                  source={{ uri: image.source.url }}
                  style={{ flex: 1 }}
                  resizeMode="contain"
                ></Image>
              </SafeAreaView>
            );
          }}
        /> */}
        <GallerySwiper
          style={{ flex: 1, backgroundColor: "transparent" }}
          initialPage={modalGalleryImage}
          images={media}
          initialNumToRender={media.length}
          onSingleTapConfirmed={() =>
            this.setState({ modalGalleryVisible: false })
          }
          imageComponent={(image, dimensions) => {
            return (
              <SafeAreaView style={{ flex: 1 }}>
                <Image
                  source={{ uri: image.source.url }}
                  style={{ flex: 1 }}
                  ç
                  resizeMode="contain"
                ></Image>
              </SafeAreaView>
            );
          }}
        />
      </Modal>
    );
  };

  const renderAddCollectionModal = (item) => {
    return (
      <GameInCollectionModal
        gameID={id}
        isVisible={modalAddCollectionVisible}
        onClose={() => setModalAddCollectionVisible(false)}
      />
    );
  };

  const render = () => {
    if (loading || !game) {
      return (
        <View style={{ flex: 1 }}>
          {renderNavigationBar()}
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner size={"large"} />
          </View>
        </View>
      );
    } else if (game && screen.ready) {
      // console.warn("game", game);
      const item = game;
      const hero = find(game.media, { type: "hero" });
      const contentInsetTop = 0;

      // console.warn(
      //   StaticSafeAreaInsets.safeAreaInsetsTop + " - " + Screen.statusBarHeight
      // );

      return (
        <View style={{ flex: 1 }}>
          <ScrollView
            style={{ flex: 1, margin: 0 }}
            //contentInsetAdjustmentBehavior="never"
            contentContainerStyle={{ marginTop: contentInsetTop }}
            //contentOffset={{x: 0, y: 0}}
            //          contentInset={{ top: contentInsetTop }}
            onScroll={Animated.event([
              { nativeEvent: { contentOffset: { y: _deltaY } } },
            ])}
            scrollEventThrottle={8} // target 120fps
            refreshControl={
              <RefreshControl
                refreshing={loading}
                onRefresh={() => getGameDetail()}
              />
            }
          >
            <BoxResponsive>
              {renderHeroImage()}
              {renderSummary(item)}
              {renderAddCollectionModal(item)}
              {/* {this.renderGallery()} */}
              {isWeb && (
                <ins
                  className="adsbygoogle"
                  style={{ display: "block", textAlign: "center" }}
                  data-ad-layout="in-article"
                  data-ad-format="fluid"
                  data-ad-client="ca-pub-3251327102100022"
                  data-ad-slot="6544943076"
                ></ins>
              )}

              <View style={{ padding: 24 }}>
                {item.franchise && (
                  <Text
                    style={{
                      fontSize: 12,
                      textTransform: "uppercase",
                      fontFamily: Theme.font.regular,
                      alignItems: "center",
                      color: Theme.colors.text,
                    }}
                  >
                    {item.franchise.name}
                  </Text>
                )}
                <Text
                  style={{
                    marginTop: 2,
                    fontSize: 18,
                    alignItems: "center",
                    color: Theme.colors.text,
                    fontFamily: Theme.font.medium,
                  }}
                >
                  Summary
                </Text>
                <Text
                  style={{
                    fontSize: 15,
                    lineHeight: 22,
                    alignItems: "center",
                    marginTop: 8,
                    color: Theme.colors.text,
                    fontFamily: Theme.font.regular,
                  }}
                >
                  {item.storyline || item.summary}
                </Text>
              </View>
              {renderReleases(item)}
            </BoxResponsive>
          </ScrollView>
          {/*{renderNavigationBar()}*/}
        </View>
      );
    } else {
      return <View></View>;
    }
  };

  useEffect(() => {
    if (!loading) getGameDetail(id);
  }, []);

  return render();
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
  preview: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  capture: {
    flex: 0,
    backgroundColor: Theme.colors.text,
    borderRadius: 5,
    padding: 15,
    paddingHorizontal: 20,
    alignSelf: "center",
    margin: 20,
  },
  country: {
    backgroundColor: Theme.colors.chrome,
    color: Theme.colors.text,
    fontSize: 12,
    fontWeight: "600",
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 4,
    marginLeft: 2,
  },
});

export default GameDetailScreen;
