import {
  Button,
  Image,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import * as React from "react";
import { NavigationContainer, useTheme } from "@react-navigation/native";
import { Theme, TurboSymbol } from "@app/ui";
import {
  createBottomTabNavigator,
  BottomTabBar,
} from "@react-navigation/bottom-tabs";
import { navigationRef } from "./navigate";
import { PlayerScreen } from "@app/music";
import {
  GamesStackScreen,
  NewsStackScreen,
  MusicStackScreen,
  UsersStackScreen,
  CollectionsStackScreen,
  HomeStackScreen,
  ActivityStackScreen,
  ManageStackScreen,
} from "@app/config/navigation/screens";
import { Environment, isElectron, NavigationLinking } from "@app/config";
import * as Analytics from "expo-firebase-analytics";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "@app/user/actions/auth.action";
import { useEffect } from "react";
import { useAccessToken, useAuthenticatedUser } from "@app/user/hooks/user";
import FontAwesome5Pro from "react-native-vector-icons/FontAwesome5Pro";
import { getPushToken } from "@app/config/firebase/firebase";

const showTabLabels = true;

// Gets the current screen from navigation state
const getActiveRouteName = (state) => {
  const route = state?.routes[state.index];

  if (route?.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route?.name;
};

const tabOptions = {
  keyboardHidesTabBar: true,
  showLabel: false,
  activeTintColor: Theme.colors.primary,
  inactiveTintColor: "#666666",
  // style: {
  //   marginTop: 64,
  // },
  labelStyle: {
    fontFamily: Theme.font.bold,
  },
  style: {
    ...Platform.select({
      android: {
        height: 60,
      },
    }),
  },
};

interface Props {
  leftMenu: boolean;
  musicPlayer: boolean;
}

const AppNavigation = ({ leftMenu, musicPlayer }: Props) => {
  const routeNameRef = React.useRef();
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const authenticatedUser = useAuthenticatedUser();
  const isLoggedIn = useSelector((state) => state.auth.logged_in);
  const user = useSelector((state) => state.auth.user);

  // @ts-ignore
  React.useEffect(() => {
    if (accessToken) {
      dispatch(authActions.setLoggedIn(accessToken));
    }
  }, [accessToken]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(authActions.getMe());
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    const state = navigationRef?.current?.getRootState();
    // Save the initial route name
    routeNameRef.current = getActiveRouteName(state);
  }, []);

  useEffect(() => {
    if (authenticatedUser?.id) {
      Analytics.setUserId(authenticatedUser.id.toString());
    } else {
      Analytics.setUserId(null);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    (async () => {
      if (authenticatedUser) {
        const pushToken = await getPushToken();
        if (pushToken) {
          dispatch(authActions.savePushToken(pushToken));
        }
      }
    })();
  }, [authenticatedUser]);

  const onStateChange = (state) => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = getActiveRouteName(state);

    if (previousRouteName !== currentRouteName) {
      // The line below uses the expo-firebase-analytics tracker
      // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
      // Change this line to use another Mobile analytics SDK
      //if (Platform.OS !== "web") {
      Analytics.setCurrentScreen(currentRouteName, currentRouteName);
      //}
      //console.warn("Screen " + currentRouteName);
    }

    // Save the current route name for later comparision
    routeNameRef.current = currentRouteName;
  };

  function MyTabBar({ state, descriptors, navigation }) {
    return (
      <View>
        {musicPlayer && Platform.OS !== "web" && (
          <SafeAreaView>
            <View style={{ height: 60 }}>
              <PlayerScreen />
            </View>
          </SafeAreaView>
        )}
        <BottomTabBar
          state={state}
          descriptors={descriptors}
          navigation={navigation}
          activeTintColor={"#fff"}
          showLabel={showTabLabels}
          labelStyle={{
            fontFamily: Theme.font.regular,
            ...Platform.select({
              android: {
                fontSize: 11,
                top: -4,
              },
            }),
          }}
          style={{
            borderTopColor: Theme.colors.chromeHigh,
            borderTopWidth: 0,
            ...Platform.select({
              web: {},
              android: {
                height: 60,
              },
              ios: {},
            }),
            display: leftMenu ? "none" : "flex",
          }}
        />
      </View>
    );
  }

  const Tab =
    Platform.OS === "android"
      ? createBottomTabNavigator() //createMaterialBottomTabNavigator()
      : createBottomTabNavigator();

  return (
    <NavigationContainer
      theme={Theme}
      ref={navigationRef}
      onStateChange={onStateChange}
      linking={isElectron ? null : NavigationLinking}
      fallback={<Text>Loading...</Text>}
    >
      <Tab.Navigator
        tabBar={MyTabBar}
        tabBarOptions={tabOptions}
        screenOptions={({ route }) => ({
          showLabel: showTabLabels,
          keyboardHidesTabBar: true,
          tabBarIcon: ({ color, size, focused }) => {
            const iconSize = showTabLabels ? 22 : 26;
            const useTk = true;

            interface IconsMap {
              [name: string]: string;
            }

            const icons: IconsMap = {
              HomeTab: "rectangle-portrait", //"home-alt",
              MusicTab: "album-collection", //"headphones",
              NewsTab: "newspaper", //"headphones",
              GamesTab: "alien-monster", //"gamepad",
              CollectionsTab: "box", //"layer-group", //"gamepad",

              ActivityTab: "comment",
              MeTab: "smile", //"user-circle",
            };

            const iconsTk: IconsMap = {
              HomeTab: "home-solid",
              MusicTab: "music-collection-solid",
              NewsTab: "news-solid",
              GamesTab: "pacman-solid", //"gamepad",
              CollectionsTab: "collections-solid", //"layer-group", //"gamepad",
              ActivityTab: "messenger-solid",
              MeTab: Environment.id + "-solid", //"user-circle",
            };

            let iconName = useTk ? iconsTk[route.name] : icons[route.name];
            const iconColor = focused ? Theme.colors.text : "#9F9F9F";

            if (useTk) {
              return (
                <TurboSymbol
                  name={iconName}
                  size={iconSize}
                  color={iconColor}
                />
              );
            }

            return focused ? (
              <FontAwesome5Pro
                name={iconName}
                solid
                size={iconSize}
                color={Theme.colors.text}
              />
            ) : (
              <FontAwesome5Pro
                name={iconName}
                light
                size={iconSize}
                color="#666"
              />
            );
          },
        })}
      >
        {Environment.features.music.enabled && (
          <Tab.Screen
            name="HomeTab"
            component={HomeStackScreen}
            options={{ tabBarLabel: "Home" }}
          />
        )}
        {Environment.features.games.enabled && (
          <Tab.Screen
            name="NewsTab"
            component={NewsStackScreen}
            options={{ tabBarLabel: "News" }}
          />
        )}
        {Environment.features.games.enabled && (
          <Tab.Screen
            name="GamesTab"
            component={GamesStackScreen}
            options={{ tabBarLabel: "Games" }}
          />
        )}
        {Environment.features.games.enabled && (
          <Tab.Screen
            name="CollectionsTab"
            component={CollectionsStackScreen}
            options={{ tabBarLabel: "Collections" }}
          />
        )}
        {Environment.features.music.enabled && (
          <Tab.Screen
            name="MusicTab"
            component={MusicStackScreen}
            options={{ tabBarLabel: "Music" }}
          />
        )}
        <Tab.Screen
          name="ActivityTab"
          component={ActivityStackScreen}
          options={{
            tabBarBadge: null,
            tabBarLabel: "Messages",
          }}
        />
        {/*{Platform.OS === "web" && (*/}
        {/*  <Tab.Screen*/}
        {/*    name="ManageTab"*/}
        {/*    component={ManageStackScreen}*/}
        {/*    options={{ tabBarLabel: "Manage" }}*/}
        {/*  />*/}
        {/*)}*/}
        <Tab.Screen
          name="MeTab"
          component={UsersStackScreen}
          options={{ tabBarLabel: "Me" }}
        />
      </Tab.Navigator>
    </NavigationContainer>
  );
};

export default AppNavigation;
