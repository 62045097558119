"use strict";
import React, { Component, ReactNode } from "react";
import {
  Text,
  View,
  SafeAreaView,
  ActivityIndicator,
  StyleSheet,
} from "react-native";
import { BlurView } from "expo-blur";
//import EStyleSheet from "react-native-extended-stylesheet";
import { Theme, Spinner } from "@app/ui";

interface Props {
  children: ReactNode;
  blur: Boolean;
  showDragPane: Boolean;
  rounded: Boolean;
  opacity: number;
  title?: string;
  margin: Boolean;
  isLoading?: Boolean;
}

interface State {}

class Card extends Component<Props, State> {
  public static defaultProps = {
    blur: false,
    showDragPane: true,
    rounded: true,
    opacity: 1,
    margin: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  renderDragPane() {
    return (
      <View style={{ padding: 14, alignItems: "center" }}>
        <View
          style={{
            height: 5,
            width: 36,
            backgroundColor: Theme.colors.text,
            opacity: 0.3,
            borderRadius: 5,
          }}
        ></View>
      </View>
    );
  }

  renderTitle() {
    return (
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{this.props.title}</Text>
      </View>
    );
  }

  renderContent() {
    return (
      <View style={{ marginBottom: 24 }}>
        {this.props.showDragPane && this.renderDragPane()}
        {this.props.title && this.renderTitle()}
        <View
          style={{
            marginVertical: 32,
            marginHorizontal: 32,
          }}
        >
          {this.props.children}
        </View>
      </View>
    );
  }

  renderLoading() {
    return (
      <View>
        {this.props.showDragPane && this.renderDragPane()}
        <Spinner
          color="#ffffff"
          size="large"
          marginSize="large"
          style={{ flex: 1, margin: 44 }}
        />
      </View>
    );
  }

  render() {
    const borderRadius = this.props.rounded ? 16 : 0;
    const margin = this.props.margin ? 0 : 0;

    if (this.props.blur) {
      const blurAmount = this.props.blur ? 10 : 0;
      return (
        <BlurView
          blurType="dark"
          blurAmount={blurAmount}
          style={{ borderRadius }}
        >
          {this.renderContent()}
        </BlurView>
      );
    } else {
      return (
        <View
          style={[
            styles.container,
            //styles.containerShadow,
            { borderRadius, margin },
          ]}
        >
          {this.props.isLoading ? this.renderLoading() : this.renderContent()}
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  title: {
    height: 44,
    lineHeight: 44,
    fontSize: 17,
    textAlign: "center",
    //fontFamily: "$fontFamilyMedium",
    alignItems: "center",
    color: Theme.colors.text, //"$textColor",
    letterSpacing: -0.7,
  },
  titleContainer: {
    borderBottomWidth: 0.5,
    //borderBottomColor: "$lineSeparatorHigh",
  },
  container: {
    backgroundColor: Theme.colors.chromeHigh, // "$chromeHighColor",
    //overflow: 'hidden',
    //borderTopColor: Theme.colors.separatorHigh, // "$lineSeparatorHigh",
    borderWidth: 0,
  },
  containerShadow: {
    shadowColor: "black",
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 1,
    shadowRadius: 20,
    elevation: 18,
  },
});

export default Card;
