import React, { useRef, useState } from "react";
import { Platform, Pressable, StyleSheet, TextInput, View } from "react-native";
import Player from "react-player/lazy";
import { Theme, Text, ImageBox } from "@app/ui";
import { Track } from "@app/music/models/track";
import { usePlayerQueue } from "@app/music/services/player.web";
import style from "../styles/range.css";
import format from "format-duration";
import FontAwesome5Pro from "react-native-vector-icons/FontAwesome5Pro";

interface ProgressStatus {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

export default ({}) => {
  const playerRef = useRef(null);
  const [index, setIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState<ProgressStatus>({
    played: 0,
    playedSeconds: 0,
    loadedSeconds: 0,
    loaded: 0,
  });
  const [seeking, setSeeking] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [muted, setMuted] = useState(false);
  const [speed, setSpeed] = useState(1);
  // const [url, setUrl] = useState(
  //   "https://cdn.thefunbots.com/file/thefunbots-cdn/tests/music/maniacs.mp3?1"
  // );
  const queue = usePlayerQueue();
  const currentSong: Track = queue[0];
  const nextSong: Track = queue[1];
  const urls = queue.map((track: Track) => track.url);

  const leading = true;
  const formattedPosition = format(progress.playedSeconds * 1000, { leading });
  const formattedDuration = format(progress.loadedSeconds * 1000, { leading });
  //const formattedSecondsLeft = format(secondsLeft * 1000, { leading });
  console.log("Speed " + speed);

  const renderTrackInfo = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        <View>
          <ImageBox height={48} source={{ uri: currentSong?.artwork }} />
        </View>
        <View style={{ paddingLeft: 16, justifyContent: "center" }}>
          <Text style={{ fontFamily: Theme.font.medium }}>
            {currentSong?.title}
          </Text>
          <Text
            style={{
              color: Theme.colors.textSecondary,
              fontSize: 13,
              marginTop: 4,
            }}
          >
            {currentSong?.artist}
          </Text>
        </View>
      </View>
    );
  };

  const renderPlayerControls = () => {
    return (
      <>
        <View style={{ flexDirection: "row", marginBottom: 4 }}>
          <View style={[styles.controlsButtonWrapper]}>
            <Pressable onPress={() => setPlaying(false)}>
              <FontAwesome5Pro
                name="backward"
                size={20}
                color={Theme.colors.text}
                solid
              />
              {/*<Ionicons*/}
              {/*  name="ios-skip-backward"*/}
              {/*  size={20}*/}
              {/*  color={Theme.colors.text}*/}
              {/*/>*/}
            </Pressable>
          </View>
          <View style={[styles.controlsButtonWrapper]}>
            {playing && (
              <Pressable onPress={() => setPlaying(false)}>
                <FontAwesome5Pro
                  name="pause-circle"
                  solid
                  size={38}
                  color={Theme.colors.text}
                />
                {/*<Ionicons*/}
                {/*  name="ios-pause"*/}
                {/*  size={38}*/}
                {/*  color={Theme.colors.text}*/}
                {/*/>*/}
              </Pressable>
            )}
            {!playing && (
              <Pressable onPress={() => setPlaying(true)}>
                <FontAwesome5Pro
                  name="play-circle"
                  solid
                  size={38}
                  color={Theme.colors.text}
                />
              </Pressable>
            )}
          </View>
          <View style={[styles.controlsButtonWrapper]}>
            <Pressable
              onPress={() => alert(nextSong?.title)}
              disabled={!nextSong}
            >
              <FontAwesome5Pro
                name="forward"
                size={20}
                color={Theme.colors.text}
                solid
              />

              {/*<Ionicons*/}
              {/*  name="ios-skip-forward"*/}
              {/*  size={20}*/}
              {/*  color={Theme.colors.text}*/}
              {/*/>*/}
            </Pressable>
          </View>
        </View>
        <View style={{ flexDirection: "row", marginTop: 5 }}>
          {/*<View*/}
          {/*  style={{ height: 6, borderRadius: 6, backgroundColor: "red" }}*/}
          {/*></View>*/}
          <Text style={[styles.numberPosition, { textAlign: "right" }]}>
            {formattedPosition}
          </Text>
          <View style={{ justifyContent: "center", marginHorizontal: 4 }}>
            <input
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={progress?.played}
              onMouseDown={() => setSeeking(true)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setProgress({
                  ...progress,
                  played: parseFloat(e.target.value),
                });
              }}
              onMouseUp={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSeeking(false);
                playerRef.current.seekTo(parseFloat(e.target.value));
              }}
              style={{
                ...style,
                backgroundSize: progress?.played * 100 + "% 100%",
                width: 420,
              }}
            />
          </View>
          <Text style={styles.numberPosition}>{formattedDuration}</Text>
        </View>
      </>
    );
  };

  const renderPitch = () => {
    const steps = 100;
    const max = 0.16;
    return (
      <View style={{ flexDirection: "row" }}>
        <View style={[styles.controlsButtonWrapper]}>
          <Pressable onPress={() => setMuted(!muted)}>
            <FontAwesome5Pro
              name={"tachometer"}
              size={16}
              solid
              color={Theme.colors.text}
              style={{ marginTop: -2 }}
            />
          </Pressable>
        </View>
        <View style={{ justifyContent: "center", paddingBottom: 4 }}>
          <input
            type="range"
            min={-steps}
            max={steps}
            step={0}
            // value={() => {
            //   const top = 0.08;
            //   const n = (top / 100) * speed;
            //   return n + 1;
            // }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              //console.log("Value " + parseFloat(e.target.value));
              const value = parseFloat(e.target.value);

              const speed = (max / steps) * value;
              setSpeed(speed + 1);
            }}
            style={{
              ...style,
              backgroundSize: speed * 100 + "% 100%",
              width: 120,
            }}
          />
        </View>
      </View>
    );
  };

  const renderExtra = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        <View style={[styles.controlsButtonWrapper]}>
          <Pressable onPress={() => setMuted(!muted)}>
            <FontAwesome5Pro
              name={muted ? "volume-slash" : "volume-up"}
              size={16}
              solid
              color={Theme.colors.text}
              style={{ marginTop: -2 }}
            />
          </Pressable>
        </View>
        <View style={{ justifyContent: "center", paddingBottom: 4 }}>
          <input
            type="range"
            min={0}
            max={1}
            step="any"
            value={volume}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setVolume(parseFloat(e.target.value));
            }}
            style={{
              ...style,
              backgroundSize: volume * 100 + "% 100%",
              width: 120,
            }}
          />
        </View>
      </View>
    );
  };

  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <Player
        width={0}
        height={0}
        playing={playing}
        url={currentSong?.url}
        volume={volume}
        muted={muted}
        controls={false}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        playbackRate={speed}
        onProgress={(progress) => {
          if (!seeking) {
            setProgress(progress);
          }
        }}
        onReady={() => {
          const p = playerRef.current.getInternalPlayer();
        }}
        ref={playerRef}
      />
      <View style={[styles.barSlot, {}]}>{renderTrackInfo()}</View>
      <View style={[styles.barSlot, { flexGrow: 1, alignItems: "center" }]}>
        {renderPlayerControls()}
      </View>
      <View style={[styles.barSlot, { alignItems: "flex-end" }]}>
        {renderPitch()}
      </View>
      <View style={[styles.barSlot, { alignItems: "flex-end" }]}>
        {renderExtra()}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  barSlot: {
    justifyContent: "center",
    marginHorizontal: 24,
    flex: 0.3333,
  },
  controlsButtonWrapper: {
    paddingHorizontal: 12,
    justifyContent: "center",
  },
  numberPosition: {
    color: Theme.colors.textSecondary,
    fontSize: 11,
    width: 60,
  },
});
