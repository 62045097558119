import * as React from "react";
import {
  Text,
  ScrollView,
  View,
  Platform,
  Image,
  Pressable,
  SafeAreaView,
} from "react-native";
import { SidebarButton, SidebarHeader, Theme, TurboSymbol } from "@app/ui";
import { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { NavigationContext } from "@react-navigation/native";
import { navigationRef } from "@app/config/navigation/navigate";
import { useAccessToken, useAuthenticatedUser } from "@app/user";
import { Environment } from "@app/config";
import { useIsAdmin } from "@app/user/hooks/user";
import { useLeftBarWidth } from "@app/utils/hooks/screen_dimensions";
import tailwind from "tailwind-rn";

interface Props {
  size?: SidebarSize;
}

enum SidebarSize {
  Compact = 0,
  Extended = 1,
}

export default () => {
  const [selectedTab, setSelectedTab] = useState("Home");
  const authenticatedUser = useAuthenticatedUser();
  const accessToken = useAccessToken();
  const isAdmin = useIsAdmin();
  const [size, setSize] = useState(SidebarSize.Extended);
  const [isCompact, setIsCompact] = useState(size === SidebarSize.Compact);

  let tabs = [];

  const SidebarContainer = (props) => {
    return (
      <View {...props} style={{ marginVertical: 12 }}>
        {props.children}
      </View>
    );
  };

  if (Environment.features.music.enabled) {
    tabs.push(
      {
        text: "Home",
        iconName: "home-lg",
        symbol: "home",
        selected: false,
        tab: "HomeTab",
      },
      // {
      //   text: "Explore",
      //   icon: require("@assets/icons/tabs/icon_tab_search.png"),
      //   iconName: "search",
      //   selected: false,
      //   tab: "HomeTab",
      // },
      {
        text: "Music",
        iconName: "album-collection",
        symbol: "music-collection",
        selected: false,
        tab: "MusicTab",
      }
    );
  }

  if (Environment.features.games.enabled) {
    tabs.push(
      {
        text: "News",
        iconName: "newspaper",
        symbol: "news",
        selected: false,
        tab: "NewsTab",
      },
      {
        text: "Games",
        iconName: "alien-monster",
        symbol: "pacman",
        selected: false,
        tab: "GamesTab",
      },
      {
        text: "Collections",
        iconName: "box",
        symbol: "collections",
        selected: false,
        tab: "CollectionsTab",
      }
    );
  }

  if (authenticatedUser) {
    tabs.push({
      text: "Messages",
      iconName: "comment",
      symbol: "messenger",
      selected: false,
      tab: "ActivityTab",
      badge: __DEV__ ? 1 : undefined,
    });
  }

  if (isAdmin) {
    tabs.push({
      text: "Manage",
      iconName: "solar-panel",
      selected: false,
      tab: "ManageTab",
      symbol: "manager",
    });
  }

  tabs.push({
    text: authenticatedUser ? authenticatedUser.profile.name : "Me",
    iconName: "user-circle",
    symbol: Environment.id,
    selected: false,
    tab: "MeTab",
  });

  if (__DEV__) {
    tabs.push({
      text: "Debug",
      symbol: "debug",
      selected: false,
      tab: "DebugTab",
    });
  }

  const renderLogo = () => {
    const resizeMode = "contain";
    const style =
      size === SidebarSize.Compact
        ? {
            marginHorizontal: 4,
          }
        : {
            marginHorizontal: 20,
          };
    const height = 112;

    return (
      <Pressable
        onPress={() =>
          setSize(
            size === SidebarSize.Compact
              ? SidebarSize.Extended
              : SidebarSize.Compact
          )
        }
        style={{
          display: "flex",
          flex: 1,
          ...style,
          marginVertical: 16,
        }}
      >
        {Environment.id === "retrollector" && (
          <Image
            source={require("@assets/brand/retrollector_sidebar_2.png")}
            style={{ maxWidth: "100%", resizeMode, height: height }}
          />
        )}
        {Environment.id === "groovr" && (
          <Image
            source={require("@assets/brand/groovr_sidebar.png")}
            style={{ maxWidth: "100%", resizeMode, height: height }}
          />
        )}
      </Pressable>
    );
  };

  const [width, setWidth] = useState(SidebarSize.Compact ? 76 : 220);

  useEffect(() => {
    setWidth(size === SidebarSize.Compact ? 76 : 220);
    setIsCompact(size === SidebarSize.Compact);
  }, [size]);

  return (
    <SafeAreaView
      style={[
        {
          width: width,
          backgroundColor: Theme.colors.chrome,
          borderRightWidth: 0.5,
          //borderRightColor: Theme.colors.chromeHigh,
        },
        tailwind("h-full"),
      ]}
    >
      <ScrollView
        style={{
          paddingVertical: 16,
          paddingHorizontal: 12,
          paddingTop: 24,
          ...Platform.select({
            web: {
              WebkitUserSelect: "none",
              WebkitAppRegion: "drag",
            },
          }),
        }}
      >
        {/*<SidebarHeader text="Music" size="big" />*/}
        {renderLogo()}
        <SidebarContainer>
          {tabs.map((button) => {
            return (
              <SidebarButton
                key={button.tab}
                text={button.text}
                compact={isCompact}
                selected={button.text === selectedTab}
                iconName={button.iconName}
                symbol={
                  button.symbol +
                  (button.text === selectedTab ? "-solid" : "-solid")
                }
                badge={button.badge}
                onPress={() => {
                  setSelectedTab(button.text);
                  navigationRef.current?.navigate(button.tab);
                }}
              />
            );
          })}
        </SidebarContainer>
        {__DEV__ && !isCompact && (
          <SidebarContainer>
            <SidebarHeader text="Biblioteca" size="normal" />
          </SidebarContainer>
        )}
      </ScrollView>
      {__DEV__ && !isCompact && (
        <View
          style={{
            height: 60,
            paddingHorizontal: 16,
          }}
        >
          <Text style={{ color: "#fff" }}>
            {accessToken ? "Has access token" : "NOT AT"}
          </Text>
          <Text style={{ color: "#fff" }}>
            {authenticatedUser ? "Logged IN" : "NOT Logged In"}
          </Text>
          {authenticatedUser && (
            <Text style={{ color: "#fff" }}>
              {authenticatedUser.profile.name}
            </Text>
          )}
          <Text style={{ color: "#fff" }}>{Environment.name}</Text>
        </View>
      )}
    </SafeAreaView>
  );
};
