import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import {
  Pressable,
  Platform,
  View,
  ImageSourcePropType,
  ImageStyle,
  NativeEventEmitter,
  StyleSheet,
  ViewStyle,
  StyleProp,
} from "react-native";
import { Theme } from "@app/ui";

const Image =
  Platform.OS === "web"
    ? require("react-native").Image
    : require("react-native-fast-image");

interface Props {
  text?: string;
  backgroundColor?: string;
  borderWidth?: number;
  borderRadius?: number;
  badge?: string | number;
  width?: number;
  height?: number;
  aspectRatio?: number;
  source: ImageSourcePropType;
  sourceThumb?: ImageSourcePropType;
  style?: ViewStyle;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: any;
}

interface ImageBoxSize {
  width: number;
  height: number;
  aspectRatio?: number;
}

export default ({
  text,
  backgroundColor = "transparent",
  borderWidth = 0.5,
  borderRadius = 2,
  badge,
  width,
  height,
  aspectRatio = 1,
  source,
  sourceThumb,
  onPress,
  style,
  containerStyle,
}: Props) => {
  let newHeight = height;
  let newWidth = width;

  if (width && height) {
    //console.log("WH");
  } else if (width && !height) {
    //console.log("W", { newWidth, newHeight });
    newHeight = newWidth / aspectRatio;
  } else if (!width && height) {
    //console.log("H");
    newWidth = newHeight * aspectRatio;
  }

  const [layout, setLayout] = useState({
    x: 0,
    y: 0,
    width: newWidth,
    height: newHeight,
    left: 0,
    top: 0,
  });

  const [size, setSize] = useState({});
  //const [height, setHeight] = useState(width * aspectRatio);

  const onViewLayout = (event) => {
    if (width && height) {
      //console.log("WH");
    } else if (width && !height) {
      //console.log("W", { newWidth, newHeight });
    } else if (!width && height) {
      //console.log("H");
      // setLayout({
      //   ...layout,
      //   width: event.nativeEvent.layout.height * aspectRatio,
      //   height: event.nativeEvent.layout.height,
      // });
    } else if (!width && !height) {
      setLayout({
        ...layout,
        width: event.nativeEvent.layout.width,
        height: event.nativeEvent.layout.width / aspectRatio,
      });
    }

    //setLayout(event.nativeEvent.layout);
    //console.log(event.nativeEvent.layout);
    //if (!height) {
    //setHeight(event.nativeEvent.layout.width / aspectRatio);
    //}
    //
  };

  const renderImage = () => {
    const sharedStyle = {
      width: layout.width,
      height: layout.height,
      // borderWidth,
      // borderColor: Theme.colors.separator,
      borderRadius,
      //aspectRatio: aspectRatio,
      position: "absolute",
    };

    return (
      <>
        {sourceThumb && (
          <Image
            source={sourceThumb}
            style={[sharedStyle, style, { zIndex: 0 }]}
          />
        )}
        <Image source={source} style={[sharedStyle, style, { zIndex: 1 }]} />
      </>
    );
  };

  const renderView = () => {
    return (
      <View
        style={[
          containerStyle,
          {
            width: layout.width,
            height: layout.height,
            backgroundColor: backgroundColor,
            borderWidth,
            borderColor: Theme.colors.separator,
            borderRadius,
            overflow: "hidden",
          },
        ]}
        onLayout={onViewLayout}
      >
        {renderImage()}
      </View>
    );
  };

  const renderPressableWithView = () => {
    return <Pressable onPress={onPress}>{renderView}</Pressable>;
  };

  if (onPress) {
    return renderPressableWithView();
  } else {
    return renderView();
  }
};
