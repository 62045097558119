interface AuthState {
  logged_in: boolean;
  loading: boolean;
  access_token?: string;
  user?: AuthUserState;
}

interface AuthUserState {
  id: number;
  profile: UserProfileState;
  accounts: Array<UserProfileState>;
  features: Array<string>;
}

interface UserProfileState {
  name: string;
  username?: string;
  country?: string;
  avatar?: string;
}

interface UserAccountState {
  platform: string;
  account_id: string;
  public?: boolean;
}

const auth: AuthState = {
  logged_in: false,
  loading: false,
  access_token: undefined,
  user: undefined,
};

export default {
  auth: auth,
  player: {
    queue: [],
  },
};
