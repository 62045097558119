import initialState from "@app/config/redux/initialState";

export default function (state = initialState.auth, action) {
  switch (action.type) {
    case "logged_in":
      return {
        ...state,
        logged_in: true,
        access_token: action.accessToken,
      };
    case "me_success":
      return {
        ...state,
        user: action.user,
      };
    case "logout":
      return {
        ...state,
        logged_in: false,
        access_token: undefined,
        user: undefined,
      };
    default:
      return state;
  }
}
