"use strict";
import React, { Component, useEffect, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  SafeAreaView,
  Pressable,
} from "react-native";
import {
  TouchableOpacity,
  BorderlessButton,
  RectButton,
  BaseButton,
  ScrollView,
} from "react-native-gesture-handler";
import { includes } from "lodash";
import { StyleSheet, CellGroup, CellItem, BoxResponsive } from "@app/ui";

import LoginModal from "@app/auth/components/login.modal";
import { connect, useDispatch, useSelector } from "react-redux";
import * as authActions from "@app/user/actions/auth.action";
import { bindActionCreators } from "redux";
import { Navigation } from "react-native-navigation";
import { openNewStoryReal } from "@app/services/navigator";

import DeviceInfo from "react-native-device-info";
import { dashboardUrl } from "@app/config/api/api";
import { Environment, isWeb } from "@app/config";

interface Props {
  componentId: string;
  user?: Object;
  loggedIn: boolean;
}

interface State {
  loginModalVisible: boolean;
}

const MeScreen = (props: Props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     loginModalVisible: false,
  //   };
  //   this.props.auth.getMe();
  //
  //   Navigation.events().registerBottomTabLongPressedListener(
  //     ({ selectedTabIndex }) => {
  //       if (selectedTabIndex === 4) {
  //         if (this.canOpenAdministration()) {
  //           openAdministration();
  //         }
  //       }
  //       console.warn('Index', selectedTabIndex);
  //     },
  //   );
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.user !== this.props.user) {
  //   }
  // }
  const { navigation } = props;
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.auth.logged_in);
  const user = useSelector((state) => state.auth.user);

  const [loginModalVisible, setLoginModalVisible] = useState(false);

  const renderLoginModal = () => {
    return (
      <LoginModal
        isVisible={loginModalVisible}
        onClose={() => {
          setLoginModalVisible(false);
        }}
      />
    );
  };

  const getAppVersionString = () => {
    return (
      DeviceInfo.getApplicationName() +
      " v" +
      DeviceInfo.getVersion() +
      "-" +
      DeviceInfo.getBuildNumber()
    );
  };

  const canOpenAdministration = () => {
    return user && includes(user.features, "admin");
  };

  const askForLogout = () => {
    if (isWeb) {
      if (confirm("Are you sure?")) {
        dispatch(authActions.logout());
      }
    } else {
      Alert.alert("Logout", "Are You Sure?", [
        {
          text: "No",
          style: "cancel",
        },
        {
          text: "Yes",
          onPress: () => dispatch(authActions.logout()),
        },
      ]);
    }
  };

  const renderLoggedIn = () => {
    return (
      <BoxResponsive>
        <CellGroup title="Dashboard">
          <CellItem
            title="My Account"
            accessoryText={user?.profile?.name || "User"}
            disclosure={true}
            iconName="smile"
          />
          <CellItem title="Settings" disclosure={true} symbol="settings-line" />

          {canOpenAdministration() && (
            <CellItem
              title="Manage"
              onPress={() =>
                navigation.push("WebView", {
                  url: dashboardUrl("/"),
                })
              }
              //iconName={"solar-panel"}
              symbol={"manager-line"}
              disclosure={true}
            />
          )}
          {/* <CellItem
            title="Large items goes here without multi line hahahahah"
            accessoryText="Hola que tal"
          /> */}

          <CellItem title="Logout" onPress={askForLogout} />
        </CellGroup>
        <CellGroup title="About">
          <CellItem
            title="Credits"
            iconName="search"
            onPress={() => Linking.openURL("https://" + Environment.host)}
          />
          <CellItem
            title="Terms & Conditions"
            iconName="file-contract"
            onPress={() =>
              Linking.openURL("https://" + Environment.host + "/terms")
            }
          />
          <CellItem
            title="Privacy Policy"
            iconName="user-secret"
            onPress={() =>
              Linking.openURL("https://" + Environment.host + "/privacy")
            }
          />
        </CellGroup>
        <CellGroup title={getAppVersionString()} />
      </BoxResponsive>
    );
  };

  const renderNotLoggedIn = () => {
    return (
      <View style={{ paddingHorizontal: 26, paddingTop: 8 }}>
        <Text style={{ color: "#fff" }}>Not Logged In</Text>
        <Pressable onPress={() => setLoginModalVisible(true)}>
          <Text style={styles.link}>Login</Text>
        </Pressable>
        <Pressable onPress={() => navigation.push("NewsList")}>
          <Text style={styles.link}>News</Text>
        </Pressable>
        {renderLoginModal()}
      </View>
    );
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(authActions.getMe());
    }
  }, [isLoggedIn]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1 }}>
        {isLoggedIn && user && renderLoggedIn()}
        {!isLoggedIn && renderNotLoggedIn()}
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  link: {
    marginVertical: 16,
    color: "blue",
    //color: "$textColor",
  },
});

export default MeScreen;

//export default connect(mapStateToProps, mapDispatchToProps)(MeScreen);
