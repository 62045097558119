"use strict";
import React, { Component, useLayoutEffect } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Dimensions,
  Platform,
} from "react-native";
import {
  TouchableOpacity,
  BorderlessButton,
  RectButton,
  BaseButton,
  ScrollView,
} from "react-native-gesture-handler";
import {
  StyleSheet,
  Theme,
  BoxResponsive,
  Spinner,
  Image,
  ImageFit,
} from "@app/ui";
import { find } from "lodash";
import { formatRelative, subDays } from "date-fns";
import Markdown, { getUniqueID } from "react-native-markdown-renderer";
import WebView from "react-native-webview";
import * as WebBrowser from "expo-web-browser";
import * as Linking from "expo-linking";
import { getNewsStory } from "@app/news/services/data";

export enum StoryDisplayMode {
  Webview = "webview",
  Native = "native",
}

interface Props {
  id: any;
  story?: Object;
  mode?: StoryDisplayMode;
}

interface State {
  mode: StoryDisplayMode;
  ready: Boolean;
  story: Object;
}

const openNewStoryReal = async (url: string) => {
  return await WebBrowser.openBrowserAsync(url);
};

class NewsStoryScreen extends Component<Props, State> {
  static defaultProps: {
    mode: StoryDisplayMode.Native;
  };

  constructor(props) {
    super(props);
    //Navigation.events().bindComponent(this);

    this.state = {
      ready: !!this.props.route.params.story,
      mode: this.props.route.params.mode,
      story: this.props.route.params.story,
    };

    if (!this.state.story) {
      getNewsStory(this.props.route.params.id).then((response) => {
        const { data } = response;

        this.setState({
          story: data.data,
        });
      });
    }
  }

  // navigationButtonPressed({ buttonId }) {
  //   if (buttonId === "link") {
  //     openNewStoryReal(this.props.componentId, this.props.story.url);
  //   }
  // }

  renderLoading() {
    return (
      <View
        style={{ flex: 1, position: "absolute", backgroundColor: "#00000090" }}
      >
        <Spinner color="#fff"></Spinner>
      </View>
    );
  }

  renderIframe() {
    return (
      <BoxResponsive
        borderRadius={6}
        overflow="hidden"
        style={{ flex: 1, overflow: "hidden" }}
      >
        <iframe
          src={this.state.story.url}
          style={{ flex: 1, border: "none" }}
        ></iframe>
      </BoxResponsive>
    );
  }

  renderWebview() {
    return (
      <WebView
        style={{
          flex: 1,
          backgroundColor: "transparent", //StyleSheet.value('$backgroundColor'),
        }}
        source={{
          uri: this.state.story.url,
        }}
        //renderLoading={this.renderLoading}
        sharedCookiesEnabled={true}
        //incognito={true}
        allowsInlineMediaPlayback={true}
        thirdPartyCookiesEnabled={false}
        hideKeyboardAccessoryView={true}
        applicationNameForUserAgent={"Retrollector"}
        startInLoadingState={false}
      ></WebView>
    );
  }

  renderMarkdown() {
    const item = this.state.story;
    const cover = find(item.media, { type: "cover" });
    const itemId = "story-image" + item.id;

    const { navigation } = this.props;

    // useLayoutEffect(() => {
    //   navigation.setOptions({
    //     title: item.title,
    //   });
    // });

    return (
      <ScrollView style={{ flex: 1, paddingHorizontal: 26, paddingTop: 16 }}>
        <BoxResponsive>
          <TouchableOpacity
            underlayColor={Theme.colors.text}
            onPress={() => openNewStoryReal(item.url)}
          >
            <Text style={styles.newsItemTitle}>{item.title}</Text>
          </TouchableOpacity>
          <View style={{ flexDirection: "row", opacity: 0.5, marginBottom: 6 }}>
            <Text style={styles.newsItemSourceTitleText} numberOfLines={1}>
              {item.site.name} -
            </Text>
            <Text style={styles.newsItemDateText} numberOfLines={1}>
              {formatRelative(new Date(item.published_at), new Date())}
            </Text>
          </View>
          {cover && (
            // <Navigation.Element elementId={itemId}>
            <Image
              source={{
                uri: find(item.media, {
                  type: "cover",
                }).url.replace("http:", "https:"),
              }}
              style={{
                //height: 96,
                marginVertical: 8,
                marginLeft: -26,
                marginRight: 0,
                width: Dimensions.get("window").width,
                aspectRatio: cover.aspect_ratio,
              }}
            />
            // </Navigation.Element>
          )}
          <Markdown rules={rules} style={stylesText}>
            {item.content}
          </Markdown>
          <View style={{ height: 32 }}></View>
        </BoxResponsive>
      </ScrollView>
    );
  }

  render() {
    if (this.state.story) {
      if (Platform.OS === "web") {
        if (this.state.mode === StoryDisplayMode.Native) {
          return this.renderMarkdown();
        } else {
          return this.renderIframe();
        }
      } else {
        if (this.state.mode === StoryDisplayMode.Native) {
          return this.renderMarkdown();
        } else {
          return this.renderWebview();
        }
      }
    } else {
      return <Spinner />;
    }
  }
}

const styles = StyleSheet.create({
  newsItemTitle: {
    fontSize: 32,
    lineHeight: 32,
    letterSpacing: -2,
    color: Theme.colors.text,
    marginBottom: 12,
    fontFamily: Theme.font.medium,
  },
  newsItemSourceTitleText: {
    textTransform: "uppercase",
    fontSize: 12,
    //flex: 1,
    marginRight: 3,
    color: Theme.colors.text,
    fontFamily: Theme.font.regular,
  },
  newsItemDateText: {
    fontSize: 12,
    textAlign: "center",
    color: Theme.colors.text,
    fontFamily: Theme.font.regular,
  },
});

const rules = {
  unknown: (node, children, parent, styles) => {
    return <View key={node.key}>{/* <Text>{node.type}</Text> */}</View>;
  },
  image: (node, children, parent, styles) => (
    <ImageFit
      key={getUniqueID()}
      //style={styles.image}
      source={{
        uri: node.attributes.src,
      }}
    ></ImageFit>
  ),
  link: (node, children, parent, styles) => {
    return (
      <Text
        key={node.key}
        style={styles.link}
        onPress={() => openNewStoryReal(node.attributes.href)}
      >
        {children}
      </Text>
    );
  },
};

const stylesText = StyleSheet.create({
  heading: {
    borderBottomWidth: 1,
    borderColor: "#000000",
  },
  heading1: {
    fontSize: 32,
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  heading2: {
    fontSize: 24,
  },
  heading3: {
    fontSize: 18,
  },
  heading4: {
    fontSize: 16,
  },
  heading5: {
    fontSize: 13,
  },
  heading6: {
    fontSize: 11,
  },
  text: {
    fontSize: 17,
    lineHeight: 24,
    color: Theme.colors.text,
    fontFamily: Theme.font.regular,
  },
  link: {
    color: Theme.colors.primary,
    //textDecorationLine: 'underline',
    //textDecorationStyle: 'dotted',
  },
  image: {
    //backgroundColor: 'red',
    //marginTop: -20,
    padding: 0,
  },
  textgroup: {
    backgroundColor: "red",
  },
});

export default NewsStoryScreen;
