"use strict";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "@app/user/actions/auth.action";
import AuthManager from "@app/auth/services/auth.service";
import { includes } from "lodash";

const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState(null);

  AuthManager.getInstance()
    .getAuthenticationToken()
    .then((pass) => setAccessToken(pass));

  return accessToken;
};

const useAuthenticatedUser = () => {
  const authenticatedUser = useSelector((state) => state.auth.user);

  return authenticatedUser;
};

const fetchAuthenticatedUser = () => {
  const dispatch = useDispatch();

  dispatch(authActions.getMe());
};

const useHasAppFeatures = () => {
  const appFeatures = useSelector((state) => state.auth?.user?.features);

  return appFeatures;
};

const useHasCredentials = () => {};

const useIsAdmin = () => {
  const user = useSelector((state) => state.auth.user);

  return user && includes(user.features, "admin");
};

export {
  useAccessToken,
  useAuthenticatedUser,
  fetchAuthenticatedUser,
  useHasAppFeatures,
  useIsAdmin,
};
