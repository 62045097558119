import { Theme as NavigationTheme } from "@react-navigation/native";
import { getColor } from "../../tailwind";

interface FontFamily {
  light: string;
  regular: string;
  medium: string;
  bold: string;
  italic: string;
}

interface Theme extends NavigationTheme {
  dark: boolean;
  colors: {
    primary: string;
    background: string;
    chrome: string;
    chromeHigh: string;
    disabled: string;
    card: string;
    text: string;
    textSecondary: string;
    border: string;
    separator: string;
    separatorHigh: string;
  };
  font: FontFamily;
}

const rubik: FontFamily = {
  light: "Rubik-Light",
  regular: "Rubik-Regular",
  medium: "Rubik-Medium",
  bold: "Rubik-Bold",
  italic: "Rubik-Italic",
};

const hind: FontFamily = {
  light: "Hind-Light",
  regular: "Hind-Regular",
  medium: "Hind-Medium",
  bold: "Hind-Bold",
  italic: "Rubik-Regular",
};

const whitney: FontFamily = {
  light: "Whitney-Light",
  regular: "Whitney-Medium",
  medium: "Whitney-Semibold",
  bold: "Whitney-Bold",
  italic: "Whitney-Book",
};

const mobileFont = rubik; //Environment.ui.fontFamily === "rubik" ? rubik : whitney;

const DarkTheme: Theme = {
  dark: true,
  colors: {
    primary: "#ff5512",
    background: getColor("black"), // "rgb(242, 242, 242)",
    chrome: "#121212",
    //chrome: getColor("gray-900"),
    chromeHigh: "#262626", // getColor("gray-800")
    disabled: "#8D8D8D", // getColor("gray-700")
    card: "#121212", //getColor("gray-900"), //"#121212", // "rgb(255, 255, 255)",
    text: "#ffffff", //"rgb(28, 28, 30)",
    textSecondary: "#818080",
    border: "transparent", //"rgb(199, 199, 204)",
    separator: "#262626",
    separatorHigh: "#262626",
  },
  font: rubik,
};

const LightTheme: Theme = {
  dark: false,
  colors: {
    primary: "#ff5512",
    background: "#fff", // "rgb(242, 242, 242)",
    chrome: "#d6d6d6",
    chromeHigh: "#cdc8c8",
    disabled: "#8D8D8D",
    card: "#e7e5e5", // "rgb(255, 255, 255)",
    text: "#000", //"rgb(28, 28, 30)",
    textSecondary: "#484848",
    border: "transparent", //"rgb(199, 199, 204)",
    separator: "#262626",
    separatorHigh: "#262626",
  },
  font: rubik,
};

export default DarkTheme;
