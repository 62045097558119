import initialState from "@app/config/redux/initialState";

export default function (state = initialState.player, action) {
  switch (action.type) {
    case "add_track":
      return {
        ...state,
        queue: [...state.queue, action.track],
      };
    default:
      return state;
  }
}
