"use strict";
import React, { Component, useEffect, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  SafeAreaView,
  Pressable,
} from "react-native";
import { useNavigation } from "@react-navigation/native";

interface Props {
  id: string;
  title: string;
}

const ConversationRow = (props: Props) => {
  const navigation = useNavigation();
  return (
    <Pressable
      style={{ flex: 1, minHeight: 60 }}
      onPress={() => navigation.push("ChatConversation", { id: props.id })}
    >
      <View style={{ flex: 1, height: 60 }}>
        <Text style={{ color: "#fff" }}>{props.title}</Text>
      </View>
    </Pressable>
  );
};

export default ConversationRow;
