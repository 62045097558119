import React, { Component, ReactNode } from "react";
import {
  Text,
  Platform,
  Image,
  ImageSourcePropType,
  Pressable,
} from "react-native";
import { RectButton } from "react-native-gesture-handler";
import { Theme } from "@app/ui";

interface Props {
  children: any;
  onPress?: any;
  style?: any;
  disabled?: boolean;
  highlightColor?: string;
  backgroundColor?: string;
}

export default ({
  children,
  onPress,
  style,
  disabled,
  highlightColor = Theme.colors.text,
  backgroundColor = Theme.colors.primary,
}: Props) => {
  const renderAndroid = () => {
    return (
      <Pressable
        onPress={onPress}
        android_ripple={{ color: highlightColor }}
        style={[style]}
        disabled={disabled}
      >
        {children}
      </Pressable>
    );
  };

  const renderiOS = () => {
    return (
      <RectButton
        onPress={onPress}
        style={style}
        underlayColor={highlightColor}
      >
        {children}
      </RectButton>
    );
  };

  const renderWeb = () => {
    return (
      <Pressable
        onPress={onPress}
        style={[{ cursor: "pointer" }, style]}
        disabled={disabled}
      >
        {children}
      </Pressable>
    );
  };

  return Platform.select({
    ios: renderiOS(),
    android: renderAndroid(),
    default: renderWeb(),
  });
};
