import { combineReducers } from "redux";
import auth from "@app/user/reducers/auth.reducer";
import player from "@app/music/reducers/player.reducer";

const rootReducer = combineReducers({
  auth,
  player,
});

export default rootReducer;
