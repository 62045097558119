"use strict";
import React, { Component, ReactNode } from "react";
import { View, Text, StyleSheet } from "react-native";
import { Theme } from "@app/ui";
//import EStyleSheet from 'react-native-extended-stylesheet';

interface Props {
  children?: ReactNode;
  title?: string;
  collapse?: boolean;
  size: "normal" | "compact";
}

interface State {}

class CellGroup extends Component<Props, State> {
  static defaultProps: Props = {
    collapse: false,
    size: "normal",
  };

  constructor(props: Props) {
    super(props);
  }

  renderTitle() {
    return <Text style={styles.title}>{this.props.title}</Text>;
  }

  renderChildren() {
    const childCount = React.Children.count(this.props.children);

    if (childCount === 1) {
      return (
        <View style={[styles.contentContainer]}>{this.props.children}</View>
      );
    }

    // @ts-ignore
    const items = this.props.children
      // @ts-ignore
      .filter((item: any) => React.isValidElement(item))
      .map((item: any, i: Number) => {
        return React.cloneElement(item, {
          isFirstItem: i === 0,
          isLastItem: i === childCount - 1,
        });
      });

    return <View style={[styles.contentContainer]}>{items}</View>;
  }

  render() {
    return (
      <View style={[styles.container, {}]}>
        {this.renderTitle()}
        {this.props.children && this.renderChildren()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    margin: 16,
  },
  contentContainer: {
    borderRadius: 12,
    overflow: "hidden",
  },
  title: {
    height: 48,
    lineHeight: 48,
    fontSize: 15,
    fontFamily: Theme.font.regular,
    color: Theme.colors.text, //"$textColor",
    marginHorizontal: 12,
  },
});

export default CellGroup;
