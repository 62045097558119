"use strict";
import React, { Component, useEffect, useState } from "react";
import {
  ActivityIndicator,
  AppRegistry,
  Text,
  View,
  FlatList,
  RefreshControl,
  Alert,
  Linking,
  Dimensions,
  SafeAreaView,
} from "react-native";
import ConversationRow from "@app/chat/components/conversation-row";
import { BoxResponsive } from "@app/ui";
import { getConversations } from "@app/chat/services/data";

interface Props {
  user?: Object;
}

const ConversationsListScreen = (props: Props) => {
  const [conversations, setConversations] = useState(null);

  useEffect(() => {
    (async () => {
      const convs = await getConversations();
      setConversations(convs.data);
    })();
  }, []);

  const renderItem = ({ item, index, section }) => {
    return <ConversationRow key={item.id} id={item.id} title={item.title} />;
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <BoxResponsive>
        <FlatList data={conversations} renderItem={renderItem}></FlatList>
      </BoxResponsive>
    </SafeAreaView>
  );
};

export default ConversationsListScreen;
