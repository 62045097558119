import * as Linking from "expo-linking";
import { Platform } from "react-native";

const prefix = Platform.OS === "web" ? Linking.makeUrl("/") : null;

const config = {
  screens: {
    HomeTab: {
      screens: {
        Home: "/",
      },
    },
    NewsTab: {
      screens: {
        NewsList: "news",
        NewsStory: "news/story/:id",
      },
    },
    GamesTab: {
      screens: {
        GamePlatformList: "platforms",
        GamesList: "games",
        GameDetail: "game/:id",
      },
    },
    CollectionsTab: {
      screens: {
        GamesCollectionsList: "collections",
        GamesCollectionsShow: "collection/:id",
      },
    },
    ActivityTab: {
      screens: {
        NotificationsList: "notifications",
        ChatConversation: "chat/:id",
        ChatConversationsList: "chats",
      },
    },
    MeTab: {
      screens: {
        UserProfile: "me",
      },
    },
    ManageTab: {
      screens: {
        PublishUpload: { path: "manage/upload", exact: true },
      },
    },
  },
};

const navigationLinking = {
  prefixes: [prefix],
  config,
};

export default navigationLinking;
