import Auth from "@app/auth/services/auth.service";
import {
  getMe as apiGetMe,
  setCredentials,
  clearCredentials,
  postPushToken,
} from "@app/auth/services/data";
import { setupFirebase } from "@app/config/firebase/firebase";
import analytics from "@react-native-firebase/analytics";
import { Platform } from "react-native";

export function getMeSuccess(user) {
  return {
    type: "me_success",
    user,
  };
}

export function getMeError(error) {
  return {
    type: "me_error",
    error,
  };
}

export function savePushToken(pushToken: string) {
  return (dispatch) => {
    postPushToken(pushToken)
      .then((response) => {})
      .catch((error) => {});
  };
}

export function getMe() {
  return (dispatch) => {
    apiGetMe()
      .then((response) => {
        const user = response.data.data;
        if (Platform.OS !== "web") {
          analytics().setUserId(user.id.toString());
          analytics().logEvent("login");
        }
        dispatch(getMeSuccess(user));
      })
      .catch((error) => {
        // if (error.response.status === 401) {
        //   Auth.resetCredentials(() => {
        //     Database.clear();
        //     screens.startWelcome();
        //     dispatch(logoutAction());
        //   });
        // }
        dispatch(getMeError(error));
      });
  };
}
export function loginAction(accessToken: string) {
  return {
    type: "logged_in",
    accessToken,
  };
}

export function logoutAction() {
  return {
    type: "logout",
  };
}

export function setLoggedIn(accessToken: string) {
  setCredentials(accessToken);
  if (Platform.OS !== "web") {
    //analytics().logEvent("login");
    //analytics().setUserId(null);
  }
  return (dispatch) => {
    dispatch(loginAction(accessToken));
  };
}

export function logout() {
  return (dispatch) => {
    clearCredentials();
    Auth.getInstance()
      .logout()
      .then(() => dispatch(logoutAction()));
  };
}
